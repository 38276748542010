import { AfterViewInit, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Store } from '@ngrx/store';

import { StoreService } from './core/store/store.service';
import { UtilsService } from './shared/services/utils.service';

import { RefreshStoreAction } from 'src/app/core/actions/actions';
import { environment } from 'src/environments/environment';
import { ApiService } from './shared/services/api.service';

import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from './shared/services/meta.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { exit } from 'process';
import { CartService } from './shared/services/cart.service';
import { lastValueFrom } from 'rxjs';


declare var $: any;
declare const gtag: Function;

@Component({
	selector: 'molla-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnInit{

	analyticsScriptElement: HTMLScriptElement;
	metaTags$: any;
	domainName: any='';
	private lastInteractionTime: number;
  	timeSinceLastInteraction: number;

	constructor(
		@Inject(Store) public store: Store<any>,
		@Inject(Router) public router: Router,
		public viewPort: ViewportScroller,
		public storeService: StoreService,
		public utilsService: UtilsService,
		public modalService: NgbModal,
		public apiService: ApiService,
		@Inject(Title) private titleService: Title,
		private metaService: MetaService,
		@Inject(Renderer2) private renderer: Renderer2,
		private deviceDetect: DeviceDetectorService,
		private cartService: CartService
		
	) {		
		if (typeof window !== 'undefined') {
			this.domainName = window.location.hostname;
		}
		//this.metaService.updateMetaTags(this.domainName);
		//this.metaTags$ = this.metaService.getMetaTags(); // Define a method in MetaService to retrieve the meta tags as an observable

		const navigationEnd = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)			
		);

		navigationEnd.pipe(first()).subscribe(() => {
			if(typeof document !== 'undefined'){
				document.querySelector('body')?.classList.add('loaded');
				var timer = setInterval(() => {
				if (typeof window !== 'undefined') 
					if( window.getComputedStyle( document.querySelector('body') ).visibility == 'visible') {
						clearInterval(timer);
						$('.owl-carousel').trigger('refresh.owl.carousel');
					}
			}, 300);
			}			
		});

		navigationEnd.subscribe((event: any) => {
			if (!event.url.includes('/shop/sidebar') && !event.url.includes('/shop/nosidebar') && !event.url.includes('/shop/market') && !event.url.includes('/blog')) {
				this.viewPort.scrollToPosition([0, 0]);
			}

			this.modalService.dismissAll();
		})
		
		if (typeof window !== 'undefined') 
			if (localStorage.getItem("molla-angular-demo") !== environment.demo) {
				this.store.dispatch(new RefreshStoreAction());
			}
		
		this.apiService.getStoredUserInfo();

		if (typeof window !== 'undefined') 
			localStorage.setItem("molla-angular-demo", environment.demo);
		
		/** START : Code to Track Page View using gtag.js */
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		  ).subscribe((event: NavigationEnd) => {
			if(typeof window !== 'undefined'){
				gtag('event', 'page_view', {
					page_path: event.urlAfterRedirects
				 })
			}			 
		  })
	   /** END : Code to Track Page View  using gtag.js */
		
	   //Add dynamic title for selected pages - Start
	   router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
          //titleService.setTitle(title);
        }
      });
      //Add dynamic title for selected pages - End

	}

	ngAfterViewInit(): void {
		
	}	

	@HostListener('window: scroll', ['$event'])
	onWindowScroll(e: Event) {
		this.utilsService.setStickyHeader();
	}

	hideMobileMenu() {
		document.querySelector('body').classList.remove('mmenu-active');
		document.querySelector('body').classList.remove('mmenu-active-iphone');
		document.querySelector('html').style.overflowX = 'unset';
	}

	async ngOnInit(): Promise<void> {	
		

		//this.metaService.updateMetaTags(this.domainName);	
		//detect device type if is mobiel and iphone for iphone errors
		let deviceInfo = this.deviceDetect.getDeviceInfo();
		this.apiService.isMobile = this.deviceDetect.isMobile();
		this.apiService.isIOS = this.deviceDetect.os;
		//console.log(this.apiService.isMobile+ " "+this.apiService.isIOS);
		//console.log(deviceInfo);

		window.addEventListener("keyup", this.disableF5);
    	window.addEventListener("keydown", this.disableF5);		
		

		//execute all the parameters and settings needed when any subpage is refreshed		
		if(typeof window !== 'undefined'){
			await this.apiService.fetchDBconnStringPromise();
			await this.apiService.storeParams();
			await this.apiService.getAboutPageElements();
			await this.apiService.getPageContactElements();
			await this.apiService.getLocation();
		}		
				
		this.cookiesExpireCheck();
		this.resetTimer();
		this.startInactivityTimer();

	}

	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:keydown', ['$event'])
	onUserInteraction(): void {
	  this.resetTimer();
	}
  
	private resetTimer(): void {
	  this.lastInteractionTime = new Date().getTime();
	}
  
	private startInactivityTimer(): void {
	  setInterval(() => {
		const currentTime = new Date().getTime();
		const elapsedSeconds = Math.floor((currentTime - this.lastInteractionTime) / (1000*60));
		this.timeSinceLastInteraction = elapsedSeconds;
		//console.log('Elasped Minutes: ',elapsedSeconds);
		if(this.timeSinceLastInteraction > this.apiService.sessionExpire && this.apiService.userInfo.length > 0){
			this.clearCookies();
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				  // Navigate to the home route
				  this.router.navigate(['/']);
			});
			this.apiService.popupMsg(this.apiService.companyName,'Session Expired', 'info');	
		}
	  }, 1000*60); // Update every 1000 milliseconds (60 seconds)
	}


	disableF5(e){
		if ((e.which || e.keyCode) == 116) e.preventDefault(); 
	}

	// collect that title data properties from all child routes
  	// there might be a better way but this worked for me
  	getTitle(state, parent) {
		var data = [];
		if(parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
    	}
		if(state && parent) {
			data.push(... this.getTitle(state, state.firstChild(parent)));
		  }
		  return data;
	}

	cookiesExpireCheck(){		
		//every time the website opens check for expiration cookies and then delete cookies
		let coookiesExpirationDate = localStorage.getItem('cookiesExpirationDate');
		if(coookiesExpirationDate == null){
			//set cookie expiration current date time
			localStorage.setItem('cookiesExpirationDate', new Date().toISOString())
		}
		else{
			let coookiesExpDT = new Date(localStorage.getItem('cookiesExpirationDate'));						
			let currentDT:Date = new Date();			
			let diff = currentDT.getTime() - coookiesExpDT.getTime();
			diff = Math.floor(diff/(1000*60));
			if( diff > this.apiService.cookieExpire){
				this.clearCookies();
				localStorage.setItem('cookiesExpirationDate', new Date().toISOString())
				localStorage.removeItem('molla');
				//clear cart
				this.cartService.cartItems.forEach(item =>{
					this.cartService.removeFromCart(item);
				});
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
					// Navigate to the home route
					this.router.navigate(['/']);
			  });
				//console.log('All Cookies Removed');
			}
		}
	}


	clearCookies(){
		// Clear cookies or perform other inactivity-related actions		  
		localStorage.removeItem('loginData');
		//also delete variable loginData
		this.apiService.userInfo = [];
		localStorage.removeItem('onlinepaymentcode');
		sessionStorage.removeItem('paypal_auth');
		localStorage.removeItem('onlinepaymentcode');
		localStorage.removeItem('shipping_id');
		localStorage.removeItem('shipping_description');
		localStorage.removeItem('shipping_cost');
		localStorage.removeItem('cartItems');
		localStorage.removeItem('totalPrice');
		localStorage.removeItem('redeemPoints');
		localStorage.removeItem('notes');
		 // Reload the current route
	}
	
}