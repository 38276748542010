import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { domainTrackingMap } from './analytics-config.js';

declare let gtag: Function; // Declare the global gtag function
//declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router) {
    this.initializeGoogleAnalytics();
  }

  private initializeGoogleAnalytics() {
    const domainName = this.getDomainName();
    const trackingCode = domainTrackingMap[domainName];
    
    if (trackingCode) {
      this.loadGoogleAnalyticsScript(trackingCode);
      this.trackPageViews();
    }
  }

  private getDomainName(): string {
    if(typeof window !== 'undefined')
      return window.location.hostname;
    else
      return '';
  }

  private loadGoogleAnalyticsScript(trackingCode: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingCode}`;
    document.head.appendChild(script);

    // The gtag function will be available after the script is loaded
    script.onload = () => this.configureGoogleAnalytics(trackingCode);
  }
  
  
  
  private configureGoogleAnalytics(trackingCode: string) {    
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());        //gtag(('js', new Date()))
    gtag('config', trackingCode);//gtag('config', trackingCode);
  }

  private trackPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', { 'page_path': event.urlAfterRedirects });
      }
    });
  }

  /*
  gtag(arg1:any, arg2:any, arg3?:any){
    window.dataLayer.push(arg1,arg2,arg3);
  }
  */
}

