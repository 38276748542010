import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'molla-stock-notify-modal',
  templateUrl: './stock-notify-modal.component.html',
  styleUrls: ['./stock-notify-modal.component.scss']
})
export class StockNotifyModalComponent implements OnInit {

  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
  }

  closeModal() {
		let modal = document.querySelector('.stock-notify-modal') as HTMLElement;
		if (modal)
			modal.click();
	}

  async notifymeclicked(event:any){
    event.preventDefault();
    let email = (<HTMLInputElement>document.getElementById("email")).value;
    if(!email.includes("@") || email == null || email.trim() == ""){
      this.apiService.popupMsg("Wrong email","Please enter a valid email","warning");
    }
    else{
      let res = await lastValueFrom(this.apiService.addCustomerToNotifyListPromise(email));
      if(res == "success"){
        this.closeModal();
      }
      else{
        this.apiService.popupMsg("Something went wrong",res,"error");
      }
    }
    
  }
}
