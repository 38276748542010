import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';


// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/others/pages.module';
import { HomeModule } from './pages/home/home.module';

// reducers
import { appReducers, metaReducers } from './core/reducers/app.reducer';
import { wishlistReducer } from './core/reducers/wishlist.reducer';
import { compareReducer } from './core/reducers/compare.reducer';
import { cartReducer } from './core/reducers/cart.reducer';

import { AppComponent } from './app.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { RegisterModule } from './pages/register/register.module';
import { TermsComponent } from './pages/legal/terms/terms.component';
import { PrivacyComponent } from './pages/legal/privacy/privacy.component';
import { ReturnsComponent } from './pages/legal/returns/returns.component';
import { ResetpasswordModule } from './pages/resetpassword/resetpassword.module';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { VivaerrorComponent } from './pages/vivaerror/vivaerror.component';
import { VivasuccessComponent } from './pages/vivasuccess/vivasuccess.component';
import { CarouselModule } from 'ngx-owl-carousel-o';





@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TermsComponent,
    PrivacyComponent,
    ReturnsComponent,        
    VivaerrorComponent,
    VivasuccessComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    CarouselModule,    
    PagesModule,
    SharedModule,
    HomeModule,
    RegisterModule,    
    ResetpasswordModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true,
    }),    
    StoreModule.forRoot(appReducers, { metaReducers }),
    StoreModule.forFeature('cart', cartReducer),
    StoreModule.forFeature('wishlist', wishlistReducer),
    StoreModule.forFeature('compare', compareReducer),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),         
  ],

  providers: [GoogleAnalyticsService, provideClientHydration()],
  bootstrap: [AppComponent],  
})

export class AppModule {
  constructor(){}
 }

