import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Product } from 'src/app/shared/classes/product';

import { ModalService } from 'src/app/shared/services/modal.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { CompareService } from 'src/app/shared/services/compare.service';

import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/services/api.service';


@Component({
    selector: 'molla-product-twelve',
    templateUrl: './product-twelve.component.html',
    styleUrls: ['./product-twelve.component.scss']
})

export class ProductTwelveComponent implements OnInit {

    @Input() product: Product;

    maxPrice = 0;
    minPrice = 99999;
    has_video:boolean = false;

    SERVER_URL = environment.SERVER_URL;

    showStock:boolean;

    allRelatedOutofStock=false;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private cartService: CartService,
        private wishlistService: WishlistService,
        private compareService: CompareService,
        public apiService: ApiService
    ) { }


    async ngOnInit(): Promise<void> {
        if(typeof window !== 'undefined'){
            this.variantsOutofStock();
            
            if(this.product.videoURL)
                this.has_video=true;

        
            let min = this.minPrice;
            let max = this.maxPrice; 
            
            this.product.variants.map(item => {
                if (min > item.price) min = item.price;
                if (max < item.price) max = item.price;
            }, []);


            if (this.product.variants.length == 0) {
                min = this.product.sale_price
                    ? this.product.sale_price
                    : this.product.price;
                max = this.product.price;
            }
            else{
                /**if this product has related and the related products are on sale. 
                 * get the first related price and set it to min 
                 * get the first related sale price and set it to max. note that zero is the option to select variant
                 * Also check if all related (variants) are out of stock   
                 * */

                let counter=0;                                
                this.product.var_rel.forEach(element =>{
                    let tmp_min = element.sale_price > 0 
                                ? element.sale_price 
                                : element.price;
                    let tmp_max = element.price;
                    min = tmp_min < min ? tmp_min : min;
                    max = tmp_max > max ? tmp_max : max;
                    //check for out of stock if all related are out of stock                               
                    if(element.stock==0)
                        counter++;                                                        
                    if(counter === this.product.variants.length)
                        this.allRelatedOutofStock = true;                                                                   
                })            
            }

            this.minPrice = min;
            this.maxPrice = max;

            this.showStock=this.apiService.showStock;
        }
    }

    addToCart(event: Event) {
        event.preventDefault();
        this.cartService.addToCart(this.product);
    }

    addToWishlist(event: Event) {
        event.preventDefault();

        if (this.isInWishlist()) {
            this.router.navigate(['/shop/wishlist']);
        } else {
            this.wishlistService.addToWishList(this.product);
        }
    }

    addToCompare(event: Event) {
        event.preventDefault();
        if (this.isInCompare()) return;
        this.compareService.addToCompare(this.product);
    }

    quickView(event: Event) {
        event.preventDefault();
        this.modalService.showQuickView(this.product);
    }

    watchVideo(event: Event, product:any){
        event.preventDefault();        
        this.modalService.videoURL = product.videoURL;
        this.modalService.showVideoModal();        
    }

    isInCompare() {
        return this.compareService.isInCompare(this.product);
    }

    isInWishlist() {
        return this.wishlistService.isInWishlist(this.product);
    }

    /**
     * Check if all related (variants) are out of stock      
     */
    variantsOutofStock(){
        let outOfStock = false;
      
        return outOfStock;
        
    }
}