import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment.prod';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/shared/services/meta.service';


@Component({
	selector: 'pages-contact-one',
	templateUrl: './contact-one.component.html',
	styleUrls: ['./contact-one.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactOnePageComponent implements OnInit {
	apiLoaded: Observable<boolean>;
	public phone;
	public email;
	public address;
	public working_hours;
	public working_days;
	public page_elements = [];
	public weekend;
	public contact_info;
	public imageName;
	public imageURL;
	public storesss:any = [];
	public domainName:string;

	

	center: google.maps.LatLngLiteral = {
        lat: this.apiService.lat,//34.84718,
        lng: this.apiService.long//33.57185
    };
	  

	
  
	constructor(httpClient: HttpClient, public apiService: ApiService, private titleService:Title, private meta: MetaService) {
		this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC7hth_jfXr7qFo3k6G0drhtlegXJfAar4', 'callback')
		.pipe(								
			map(() => true),
			catchError(() => of(false)),							
		);
	}
	
	async ngOnInit(): Promise<void> {			
		this.titleService.setTitle('Contact Us');	
		if (typeof window !== 'undefined') {
			this.domainName = window.location.hostname;
			if(this.apiService.db_conn_string == null || this.apiService.db_conn_string == ''){
				await this.apiService.fetchDBconnStringPromise();
			}
		}
		let sb:string = 'SELECT * ';
		sb+= 'FROM WAREHOUSES ';
		sb+= 'WHERE DELETEDFLAG=0';
	    this.storesss = await lastValueFrom(this.apiService.execSqlQueryPromise(sb));
	   	this.apiService.stores = this.storesss;	  					
		await this.apiService.getPageContactElements();
		this.weekend = this.apiService.weekendContact;
		this.contact_info = this.apiService.contact_info;
		this.phone = this.apiService.phone;
		this.email = this.apiService.email;
		this.address = this.apiService.address;
		this.working_hours = this.apiService.working_hoursContact;
		this.working_days = this.apiService.working_daysContact;	
		this.imageName = this.apiService.imageNameContact;
		this.imageURL = environment.Images_Path+"/" + this.apiService.img_folder + "/contact/"+(this.imageName || 'no-img.png');
		this.meta.updatePageMetaTags(this.domainName,'Contact');	
		
	}

	async sendEmail(event:any){
		event.preventDefault();
		let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		let name = (<HTMLInputElement>document.getElementById("cname")).value;
		let subject = (<HTMLInputElement>document.getElementById("csubject")).value;
		let message = (<HTMLInputElement>document.getElementById("cmessage")).value;
		let clients_email = (<HTMLInputElement>document.getElementById("cemail")).value;
		let phone = (<HTMLInputElement>document.getElementById("cphone")).value;
		//check if all required fields are valid and then the email
		if( name === '' || this.email === '' || message === ''){
			this.apiService.popupMsg("Required Fields Empty","Required fields (*) \n must not be left empty","info");
		}
		else if(!clients_email.match(mailformat) ){
			this.apiService.popupMsg("Wrong Email Format","Email format be like exa@gmail.com","info");
		}
		else{
			let res:any = await lastValueFrom(this.apiService.sendContactEmail(name,clients_email,phone,subject,message));
			if(res === "success"){
				this.apiService.popupMsg("Your email has been sent successfully","We will get back to you \n as soon as possible","success");
				this.clearFields();
			}
			else{
				this.apiService.popupMsg("Ooops something went wrong","Please try again later","wrong");
			}
		}		
	}

	clearFields(){
		(<HTMLInputElement>document.getElementById("cname")).value ='';
		(<HTMLInputElement>document.getElementById("csubject")).value ='';
		(<HTMLInputElement>document.getElementById("cmessage")).value='';
		(<HTMLInputElement>document.getElementById("cemail")).value='';
		(<HTMLInputElement>document.getElementById("cphone")).value='';
	}

}