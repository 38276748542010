import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './shared/layout/layout.component';
import { IndexComponent } from './pages/home/index/index.component';
import { VivaerrorComponent } from './pages/vivaerror/vivaerror.component';
import { VivasuccessComponent } from './pages/vivasuccess/vivasuccess.component';

const routes: Routes = [
	
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: IndexComponent
			},
			{
				path: 'vivaerror',
				pathMatch: 'full',
				component: VivaerrorComponent
			},
			{
				path: 'vivasuccess',
				pathMatch: 'full',
				component: VivasuccessComponent
			},				
			{
				path: 'pages',
				loadChildren: () => import( './pages/others/pages.module' ).then( m => m.PagesModule )
			},
			{
				path: 'shop',
				loadChildren: () => import( './pages/shop/shop.module' ).then( m => m.ShopModule )
			},
			{
				path: 'product',
				loadChildren: () => import( './pages/product/product.module' ).then( m => m.ProductModule )
			},
			{
				path: 'register',
				loadChildren: () => import( './pages/register/register.module' ).then( m => m.RegisterModule )
			},
			{
				path: 'pass-reset',
				loadChildren: () => import( './pages/resetpassword/resetpassword.module' ).then( m => m.ResetpasswordModule )
			},		
		]
	},
	{
		path: '**',
		redirectTo: '/pages/404'
	}
];

@NgModule( {
	imports: [ RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'disabled', scrollPositionRestoration: 'disabled', initialNavigation: 'enabledBlocking' }) ],
	exports: [ RouterModule ]
} )

export class AppRoutingModule { }