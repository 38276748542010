import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutOnePageComponent } from './about-one/about-one.component';
import { LoginPageComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactOnePageComponent } from './contact-one/contact-one.component';
import { TermsComponent } from '../legal/terms/terms.component';
import { PrivacyComponent } from '../legal/privacy/privacy.component';
import { ReturnsComponent } from '../legal/returns/returns.component';
import { PartnerComponent } from './partner/partner.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'about',
        pathMatch: 'full'
    },
    {
        path: 'about',
        component: AboutOnePageComponent
    },    
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: 'login',
        component: LoginPageComponent
    },    
    {
        path: 'contact',
        component: ContactOnePageComponent
    },    
    {
        path: 'terms',
        component: TermsComponent
    }, 
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'returns',
        component: ReturnsComponent
    },
    {
        path: 'partner',
        component: PartnerComponent
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PagesRoutingModule { };