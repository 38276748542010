import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { ModalService } from 'src/app/shared/services/modal.service';

declare var $: any;

@Component({
	selector: 'molla-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobileMenuComponent implements OnInit, OnDestroy {

	searchTerm = "";
	cats = [];
	masterCats = [];

	private subscr: Subscription;

	constructor(private router: Router, public apiService: ApiService, public modalService:ModalService) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.hideMobileMenu();
			}
		});
	}

	async ngOnInit(): Promise<void> {
		
		if(typeof window !== 'undefined'){
			await this.apiService.fetchDBconnStringPromise();			
			this.cats = await this.apiService.setupMenuCategoriesandFetch();
			this.apiService.setupCategories4SideBar();
		}
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	submenuToggle(e) {
		const parent: HTMLElement = e.target.closest('li');
		const submenu: HTMLElement = parent.querySelector('ul');

		if (parent.classList.contains('open')) {
			$(submenu).slideUp(300, function () {
				parent.classList.remove('open');
			});
		}
		else {
			$(submenu).slideDown(300, function () {
				parent.classList.add('open');
			});
		}

		e.preventDefault();
		e.stopPropagation();
	}

	hideMobileMenu() {
		document.querySelector('body').classList.remove('mmenu-active');
		document.querySelector('body').classList.remove('mmenu-active-iphone');
		document.querySelector('html').removeAttribute('style');
	}

	submitSearchForm(e: any) {
		e.preventDefault();
		this.searchTerm = e.currentTarget.querySelector('.form-control').value;
		//this.router.navigate(['/shop/sidebar/list'], { queryParams: { searchTerm: this.searchTerm } });
		if(this.apiService.isMobile && this.apiService.isIOS === "iOS"){
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;	
			this.router.navigate(['/shop/sidebar/list'], { queryParams: { searchTerm: this.searchTerm } });
		}
		else{
			this.router.navigate(['/shop/sidebar/list'], { queryParams: { searchTerm: this.searchTerm } });
		}
		//	this.router.navigate(['/shop/sidebar/3cols'], {queryParamsHandling: ''});
	}

	showLoginModal(event: Event): void {
		event.preventDefault();
		this.modalService.showLoginModal();
	}
}
