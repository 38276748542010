import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PagesContent } from 'src/app/shared/classes/pages-content';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'molla-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  public contentArr:PagesContent[] =[];

  constructor(private apiService: ApiService) { }
  

  async ngOnInit(): Promise<void> {   
	if(typeof window !== 'undefined')
    	this.getPageElements();
  }

  async getPageElements(){
		let sb:string ="";
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_PAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = (SELECT IQWP_ID FROM IQWEB_PAGES_PAGE WHERE iqwp_page = 'policy')";

		let res = await lastValueFrom(this.apiService.execSqlQueryPromise(sb));
		for(let i=0; i<res.length; i+=2){
			let tmp_row:PagesContent = {title:'', paragraph:''} ;			
			if (res[i].IQWPP_POSITION_DESC.trim().includes("title")){
				tmp_row.title = res[i].IQWPP_TEXT.trim();
				tmp_row.paragraph = res[i+1].IQWPP_TEXT.trim();
			}			
			this.contentArr.push(tmp_row);
		}	
	}

}
