import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/shared/services/meta.service';

@Component({
  selector: 'molla-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})

export class PartnerComponent implements OnInit {
  
  domainName:string;
  
  constructor(private titleService:Title, private meta:MetaService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Partner');
    if (typeof window !== 'undefined') {
			this.domainName = window.location.hostname;
		}
		this.meta.updatePageMetaTags(this.domainName,'Partner');
  }

}
