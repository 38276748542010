import { Component, OnInit, Input } from '@angular/core';

import { productSlider } from '../data';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
	selector: 'molla-new-collection',
	templateUrl: './new-collection.component.html',
	styleUrls:  ['./new-collection.component.scss']
})

export class NewCollectionComponent implements OnInit {

	@Input() products = [];
	@Input() loaded = false;

	sliderOption = productSlider;
	cats = ['all', 'electronics', 'furniture', 'clothing', 'accessories'];
	titles = { "all": "All", "electronics": "Electronics", "furniture": "Furniture", "clothing": "Clothes", "accessories": "Accessroies" };
	customOptions:OwlOptions;
	
	constructor(private apiService: ApiService) { }

	ngOnInit(): void {
		//set the options depending on the device
		if(this.apiService.isMobile && this.apiService.isIOS === "iOS"){
			this.customOptions ={
				autoplay:true,
				autoplaySpeed:200,
				loop: false,
				mouseDrag: false,
				touchDrag: false,
				pullDrag: false,
				dots: true,
				navSpeed: 800,			
				navText: ['&#8249', '&#8250'],//&#8249    &#8250;
				responsive: {
					0: {
						items: 1										
					},
					400: {
						items: 2
					},
					760: {
						items: 3
					},
					1000: {
						items: 4
					},
					1300:{
						items:5
					}
					},
				nav: false,	
				margin:15,
				autoplayHoverPause:false,
				autoHeight:true,							
			}
		}
		else{
			this.customOptions ={
				autoplay:true,
				autoplaySpeed:200,
				loop: true,
				mouseDrag: true,
				touchDrag: true,
				pullDrag: false,
				dots: true,
				navSpeed: 800,			
				navText: ['&#8249', '&#8250'],//&#8249    &#8250;
				responsive: {
					0: {
						items: 1										
					},
					400: {
						items: 2
					},
					760: {
						items: 3
					},
					1000: {
						items: 4
					},
					1300:{
						items:5
					}
					},
				nav: false,	
				margin:15,
				autoplayHoverPause:false,
				autoHeight:true,							
			}
		}
		
		
	}
}
