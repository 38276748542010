export const shopData = {
    "sizes": [ 
        {
            size: 'Large',
            slug: 'L'
        },        
    ],

    "colors": [      
        {
            color: '#9966cc',
            color_name: 'Purple'
        },        
    ],

    "brands": [
        {
            brand: "Lost Vape",
            slug: "lost-vape"
        },       
    ],

    "categories": [
        {
            name: "Vape",
            slug: "vape",
            count: 12
        },
   ],

    "prices": [
        {
            min: '0',
            max: '25',
            name: 'Under €25'
        },
    ]
}