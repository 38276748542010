import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { lastValueFrom } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';


@Component({
  selector: 'molla-adscube',
  templateUrl: './adscube.component.html',
  styleUrls: ['./adscube.component.scss']
})
export class AdscubeComponent {
  isUnfolded:boolean=false;
  prods:any;
  customOptions:OwlOptions;
  isLoaded = false;

  constructor(public apiService: ApiService, private router:Router){}

  async ngOnInit(){      
    let res = await lastValueFrom(this.apiService.execSqlQueryPromise("SELECT * FROM PRODUCTS WHERE DELETEDFLAG=0 AND PROD_POPUP_AD_WEB=1"));
    this.prods = res;
	  this.isLoaded = true;
  }


  cubeClicked(e:any):void{
    this.isUnfolded = !this.isUnfolded;
    var item = document.getElementById("cubeid");
    setTimeout(() => {
			// Code to execute after the delay
			item.classList.toggle("hidden");
			this.router.navigate(['product/default/'+this.prods[0].PRODID])
		}, 750);    
  }

}
