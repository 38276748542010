import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'molla-vivaerror',
  templateUrl: './vivaerror.component.html',
  styleUrls: ['./vivaerror.component.scss']
})
export class VivaerrorComponent {

  constructor(public  apiService:ApiService, private activatedRoute: ActivatedRoute,  ){
  }

  paymentid:any;

  async ngOnInit(): Promise<void> {
		if(typeof window !== 'undefined')
      await this.apiService.fetchDBconnStringPromise();
  
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRoute.queryParams.subscribe(params => {
      this.paymentid = params['s'];   
      //console.log(this.paymentid);
    });
  }

}



