import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetpasswordComponent } from './resetpassword.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResetpasswordRoutingModule } from './resetpassword-routing.module';




@NgModule({
  declarations: [
    ResetpasswordComponent
  ],
  imports: [
    CommonModule,    		
    RouterModule,
    SharedModule,  
    ResetpasswordRoutingModule 		
  ]
})
export class ResetpasswordModule { }
