<div class="mobile-menu-container mobile-menu-light">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" (click)="hideMobileMenu()"><i class="icon-close"></i></span>

        <form action="#" method="get" class="mobile-search" (submit)="submitSearchForm($event)">
            <label for="mobile-search" class="sr-only">Search</label>
            <input type="search" class="form-control" name="mobile-search" id="mobile-search" placeholder="Search in..."
                required>
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
        </form>

        <ul ngbNav #nav="ngbNav" class="nav nav-pills-mobile nav-border-anim">
            <li ngbNavItem>
                <a ngbNavLink>
                    Menu
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobile-nav">
                        <ul class="mobile-menu">
                            <li>
                                <a routerLink="/">Home</a>    
                            </li>
                            <li>
                                <a routerLink="/shop/sidebar/list">All Products</a>    
                            </li>
                            <li>
                                <a routerLink="/pages/partner">Become a Partner</a>    
                            </li>           
                            <li *ngIf="apiService.userInfo.length < 1 && apiService.showSignin">
                                <a routerLink="" (click)="showLoginModal($event)">Sign in</a>
                            </li>
                            <li *ngIf="apiService.userInfo.length < 1 && apiService.showSignin">
                                <a routerLink="/register">Sign up</a>  
                            </li>  
                            <li *ngIf="apiService.userInfo.length > 0">
                                <a routerLink="/shop/dashboard">My Account</a>   
                            </li>
                            <li>
                                <a routerLink="/shop/cart">Checkout</a>    
                            </li>
                            <li>
                                <a routerLink="/pages/about">About Us</a>    
                            </li>
                            <li>
                                <a routerLink="/pages/contact">Contact Us</a>    
                            </li>                        
                        </ul>
                    </nav>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    Categories
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobilenav"><!--mobile-cats-nav -->
                        <ul class="mobile-menu"><!--mobile-cats-menu -->
                            <li routerLinkActive="active" *ngFor="let cat of cats; let i = index" (click)="submenuToggle($event)">
                                <a routerLink="/"> <!-- routerLink="/shop/sidebar/3cols" [queryParams]="{category: cat.PCM_Description,id:cat.CATID}">{{cat.PCM_Description}}-->
                                    {{cat.PCM_Description}}
                                    <span class="mmenu-btn" (click)="submenuToggle($event)" ></span>
                                </a>  
                                <ul>                   
                                    <li *ngFor="let subcat of cat.subcategories">
                                        <a routerLink="/shop/sidebar/3cols"  [queryParams]="{subcategory: subcat.CatID}">{{subcat.CatDescription}}</a>
                                    </li>
                                </ul>                    
                            </li>                        
                        </ul>
                    </nav>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <div class="social-icons">
            <a href="javascript:;" class="social-icon" title="Facebook"><i class="icon-facebook-f"></i></a>
            <a href="javascript:;" class="social-icon" title="Twitter"><i class="icon-twitter"></i></a>
            <a href="javascript:;" class="social-icon" title="Instagram"><i class="icon-instagram"></i></a>
            <a href="javascript:;" class="social-icon" title="Youtube"><i class="icon-youtube"></i></a>
        </div>
    </div>
</div>