import { Component, Input, OnInit,   } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component( {
	selector: 'molla-video-modal',
	templateUrl: './video-modal.component.html',
	styleUrls: [ './video-modal.component.scss' ]
} )

export class VideoModalComponent implements OnInit {	
	videoURLframe;
	trustedURLframe;

	constructor ( private modalService: NgbActiveModal, private sanitizer: DomSanitizer, private videoService: ModalService ) {		
	}

	ngOnInit (): void {
		//this.videoURLframe = this.videoURL;//'<iframe width="560" height="800" src="https://www.youtube.com/embed/F4euUnBwC3Q?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';		
		this.videoURLframe = this.videoService.videoURL;
		this.trustedURLframe  = this.sanitizer.bypassSecurityTrustHtml(this.videoURLframe);		
	}

	closeModal () {
		this.modalService.dismiss();
	}

	video_url(){
		return 
	}
}