import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';

@Component({
  selector: 'molla-reward-menu',
  templateUrl: './reward-menu.component.html',
  styleUrls: ['./reward-menu.component.scss']
})
export class RewardMenuComponent implements OnInit {

  points:number=-1;

  constructor(public apiService: ApiService) { }
  
  
  ngOnInit(): void {
    if(this.apiService.userInfo.length > 0)       
      this.points = Number(this.apiService.userInfo[0].CUST_POINT_Balance) - Number(this.apiService.userInfo[0].CUST_POINTS_Onhold);    
    else      
      this.points = 0;
  }
  

}
