import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { domainMetaMap } from 'src/app/shared/countries';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'molla-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  username = "";
  password = "";
  fname = "";
  lname = "";
  company = "";
  country = "";
  street1 = "";
  //street2 = "";
  city = "";
  postcode = "";
  mobileNumber = "";  
  today = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  router: any;
  cartService: any;
  dialog: any;
  countries : any;
  public isProcessing:boolean=false;

  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
    //get countries based on domain name			
			let domainName = window.location.hostname;
			this.countries = domainMetaMap[domainName];
  }

  async registerClicked(event:any){		
    event.preventDefault();
    this.isProcessing=true;
    //#region Get Form's Data    
    this.username = (<HTMLInputElement>document.getElementById("email")).value;
    this.password = (<HTMLInputElement>document.getElementById("password")).value;
    let termsAccepted = (<HTMLInputElement>document.getElementById("terms")).checked;
    this.fname = (<HTMLInputElement>document.getElementById("firstName")).value;
    this.lname = (<HTMLInputElement>document.getElementById("lastName")).value;
    this.company = (<HTMLInputElement>document.getElementById("company")).value;
    this.country = (<HTMLInputElement>document.getElementById("country")).value;
    this.street1 = (<HTMLInputElement>document.getElementById("street1")).value;
    //this.street2 = (<HTMLInputElement>document.getElementById("street2")).value;
    this.city = (<HTMLInputElement>document.getElementById("city")).value;
    this.postcode = (<HTMLInputElement>document.getElementById("postcode")).value;    
    this.mobileNumber = (<HTMLInputElement>document.getElementById("tel")).value;        
    let mobileiSNumbers = /^\d+$/.test(this.mobileNumber);
    //#endregion
    if (this.username == "" || this.username.includes("@")==false || this.password == "" ||
             this.fname == "" ||  this.lname == "" || this.country == "" || this.street1 == "" || this.city == "" || this.postcode == "")
      {
        this.apiService.popupMsg(this.apiService.companyName,"Please fill all required fields *","warning");
        this.isProcessing=false;
      }
    else if( mobileiSNumbers == false){
      this.apiService.popupMsg(this.apiService.companyName,"Mobile should consist of numbers","info"); 
      this.isProcessing=false;  
    }      
    else if (termsAccepted != true){
      this.apiService.popupMsg(this.apiService.companyName,"Privacy policy not accepted","warning");
      this.isProcessing=false;  
    }      
    else{
      //#region check if user exists already
      let sb:string ='';
      sb += " SELECT CUST_USERNAME,CUSTMOBILEPHONE, CUST_Password, CUSTID ";
      sb += " FROM customers ";
      sb += " WHERE (CUSTMOBILEPHONE='" + this.mobileNumber + "' " + " OR CUSTEMAIL ='" + this.username + "')";
      sb += " AND DELETEDFLAG=0 ";
      const dt = await lastValueFrom(this.apiService.execSqlQueryPromise(sb));
      //check if mobile phone and subsequently the user already exists
      if(dt?.length > 0){        
        this.apiService.popupMsg(this.apiService.companyName,"Username or Phone number \n already exists!","info");   
        this.isProcessing=false;  
      }
      //else save the new user
      else
      {
        this.saveNewUser();
      }      
      //#endregion
    }    
  }

  
  async saveNewUser(){    
    type MyArrayType = Array<{Column_Name: string, Column_Value: string}>;
          
    let Col_Value: MyArrayType = [
        {Column_Name: 'CUST_USERNAME', Column_Value: this.username},
        {Column_Name: 'CUSTNAME', Column_Value: this.fname},
        {Column_Name: 'CUSTSURNAME', Column_Value: this.lname},
        {Column_Name: 'CUST_Password', Column_Value: this.password},
        {Column_Name: 'CUSTIDNUMBER', Column_Value: ''},
        {Column_Name: 'CUSTCONTACTPERSON', Column_Value: ''},
        {Column_Name: 'CUSTMOBILEPHONE', Column_Value: this.mobileNumber},
        {Column_Name: 'CUSTPHONE', Column_Value: ''},
        {Column_Name: 'CUSTADDRESS', Column_Value: this.street1 + "," + this.postcode},
        {Column_Name: 'CUSTSTATUSID', Column_Value: ''},
        {Column_Name: 'CUSTPLACEID', Column_Value: '-1'},
        {Column_Name: 'CUSTEMAIL', Column_Value: this.username},
        {Column_Name: 'CUSTFAX', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON1', Column_Value: ''},
        {Column_Name: 'TITLE1', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON1PHONE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON1MOBILE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON1EMAIL', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON1FAX', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON2', Column_Value: ''},
        {Column_Name: 'TITLE2', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON2PHONE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON2MOBILE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON2EMAIL', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON2FAX', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON3', Column_Value: ''},
        {Column_Name: 'TITLE3', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON3PHONE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON3MOBILE', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON3EMAIL', Column_Value: ''},
        {Column_Name: 'CONTACTPERSON3FAX', Column_Value: ''},      
        {Column_Name: 'IS_CUSTOMER', Column_Value: '1'},
        {Column_Name: 'DELETEDFLAG', Column_Value: '0'},                
        {Column_Name: 'DTMOD', Column_Value: this.today},
        {Column_Name: 'CUSTSALESPRICES', Column_Value: '0'},
        {Column_Name: 'CUSTCITY', Column_Value: this.city},
        {Column_Name: 'CUSTCOUNTRY', Column_Value: this.country},
        {Column_Name: 'IsPos', Column_Value: '1'},//this.apiService.Order_Mobile_Show_On_Pos},
        {Column_Name: 'TablesSequences', Column_Value: '-1'},
        {Column_Name: 'CUSTVAT', Column_Value: this.apiService.Customer_VAT_On_Mobile},//to be used with a parameter on qinvoice
        {Column_Name: 'CUST_Lat_Default', Column_Value: '0'},
        {Column_Name: 'CUST_Lot_Default', Column_Value: '0'},
        {Column_Name: 'CUST_Is_Mobile_User', Column_Value: '1'},               
    ];
  
    if ( this.apiService.Order_Mobile_Show_On_Pos == 'True' || this.apiService.Order_Mobile_Show_On_Pos == 'true' )
      Col_Value.push({Column_Name: 'CustShowOnPOS', Column_Value: '1'});   
  
      //before saving the new customer check if the chosen username already exists
      //if it exists ask the user to change it. this is because an already existing customer in iq windows may not have a username and password for web
      let _dt = await lastValueFrom(this.apiService.execSqlQueryPromise("SELECT * FROM CUSTOMERS WHERE CUST_USERNAME ='"+this.username+"' AND DELETEDFLAG=0"));
      if(_dt?.length>0){
        this.apiService.popupMsg('Username already taken','Please choose another one','warning');
      }
      else{
        let res = await lastValueFrom(this.apiService.getSavePromise("CUSTOMERS",false,Col_Value,"CUSTID"));
      
          if(res.toString() =="success" ){                                    
            this.popMsgSuccReg();            
          }
          else{            
            this.apiService.popupMsg(this.apiService.companyName,"Something went wrong please try again or contact the website admin","Warning")            
            this.router.navigate(['/signupform']);
          }
      }
      
  
      
  }
  
  popMsgSuccReg(){
    Swal.fire({
      title: "Congrats!",
      html: '<pre>'+'Registration Completed Successfully'+'</pre>',
		  icon: 'success',            
      confirmButtonText: "Thank you!",  
      allowOutsideClick: false,    
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.href="/";
      }
    });
  }
  

}//end of component
