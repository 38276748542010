<nav class="main-nav">
    <ul class="menu sf-arrows">
        <li [class.active]="current.includes('/home')">
            <a routerLink="/">Home</a>    
        </li>
        <li [class.active]="current.includes('/list')">
            <a routerLink="/shop/sidebar/list" [queryParams]="{title: 'All Products'}">All Products</a>    
        </li>
        <li [class.active]="current.includes('/Partner')">
            <a routerLink="/pages/partner">Become a Partner</a>    
        </li>
        <li [class.active]="current.includes('/Sign in')" *ngIf="apiService.userInfo.length < 1 && apiService.showSignin">
            <a routerLink="" (click)="showLoginModal($event)">Sign in</a>    
        </li>
        <li [class.active]="current.includes('/register')" *ngIf="apiService.userInfo.length < 1 && apiService.showSignup">
            <a routerLink="/register">Sign up</a>    
        </li>  
        <li [class.active]="current.includes('/dashboard')" *ngIf="apiService.userInfo.length > 0">
            <a routerLink="/shop/dashboard">My Account</a>    
        </li>  
        <li [class.active]="current.includes('/cart')">
            <a routerLink="/shop/cart">Checkout</a>    
        </li>  
        <li [class.active]="current.includes('/about')">
            <a routerLink="/pages/about">About Us</a>    
        </li>
        <li [class.active]="current.includes('/contact')">
            <a routerLink="/pages/contact">Contact Us</a>    
        </li>       
    </ul>
</nav>