<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="form-box">
        <div class="form-tab nav-fill">
            <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                <li ngbNavItem>
                    <a ngbNavLink>Notify my when product is available</a>
                    <ng-template ngbNavContent>
                        <form action="/login">
                            <div class="form-group">
                                <label for="singin-email">Email address *</label>
                                <input type="email" class="form-control" id="email" name="email" required ngModel email="true">
                            </div>                           
                            <div class="form-footer">
                                <button class="btn btn-outline-primary-2" (click)="notifymeclicked($event)">
                                    <span>Notify me</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>                                                                                                                                
                            </div>
                        </form>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>