import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';

@Component({
	selector: 'molla-login-modal',
	templateUrl: './login-modal.component.html',
	styleUrls: ['./login-modal.component.scss']
})

export class LoginModalComponent implements OnInit {

	constructor(public apiService:ApiService,@Inject(Router) public router:Router, public cartService: CartService, 
				public wishlistService: WishlistService) { }

	ngOnInit(): void {
	}

	closeModal() {
		let modal = document.querySelector('.login-modal') as HTMLElement;
		if (modal)
			modal.click();
	}

	async loginClicked(event:any){
		event.preventDefault();
		let username = (<HTMLInputElement>document.getElementById("email")).value;
		let password = (<HTMLInputElement>document.getElementById("password")).value;
	 
		if(password == "" || username == ""){     
			this.apiService.popupMsg("Required field(s) empty.",'User name or password cannot be empty',"info");			
		}
		else{
			//this.btnPressed=true;
			const loginData = await lastValueFrom(this.apiService.getLoginPromise(username, password));
			//this.apiService.userInfo = loginData;
			//this.getlogedinuser();
			if(loginData.length>0){
				if(loginData[0].CUST_USERNAME == username && password == loginData[0].CUST_Password ||
					(loginData[0].CUST_USERNAME.toLowerCase() == username.toLowerCase() && password.toLowerCase() == loginData[0].CUST_Password.toLowerCase()))
				{
					this.apiService.userInfo = loginData;					
					if (typeof window !== 'undefined') {
						localStorage.setItem('loginData',JSON.stringify(loginData));
						this.apiService.updateVATtext();
					}
						
					this.closeModal();
					//check if the customer has a pricelist then remove all items from the cart and the wishlist
					if (loginData[0].CUST_PriceLIst_Header_Id != '-1' && loginData[0].CUST_PriceLIst_Header_Id != null && loginData[0].CUST_PriceLIst_Header_Id != '')
					{
						this.cartService.cartItems.forEach(x=>{
							this.cartService.removeFromCart(x);
						});

						this.wishlistService.wishlist.forEach(x=>{
							this.wishlistService.removeFromWishList(x);
						});
						
					} 
					this.router.navigate(['/shop/dashboard']);
				}				
			}
			else{
				this.apiService.popupMsg("Wrong username / password", "Please try again!","warning");
			}             
		}
		  
	}

	async forgotPassClicked(){
		let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		let email = (<HTMLInputElement>document.getElementById("email")).value;
		if(email === '')
			this.apiService.popupMsg(this.apiService.companyName,'Please type your email first', 'info');
		else if(!email.match(mailformat)){
			this.apiService.popupMsg(this.apiService.companyName,'Email format not valid', 'error');
		}
		else{			
			
			let res = await lastValueFrom(this.apiService.forgotPassword(email));
			if(res === 'success')
				this.apiService.popupMsg(this.apiService.companyName,'Check your inbox for instructions', 'success');
			else
				this.apiService.popupMsg(this.apiService.companyName,'Email not found', 'info');
		}		
	}
	
}