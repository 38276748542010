<main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <!--
                <li class="breadcrumb-item">
                    <a href="javascript:;">Pages</a>
                </li>
                -->
                <li class="breadcrumb-item active">Contact Us</li>
            </ol>
        </div>
    </nav>

    <div class="container">
        <div class="page-header page-header-big text-center"
           style="background-image: url({{imageURL}})"> 
            <h1 class="page-title text-white">Contact us<span class="text-white">keep in touch with us</span></h1>
        </div>
    </div>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-2 mb-lg-0">
                    <h2 class="title mb-1">Contact Information</h2>
                    <p class="mb-3">{{contact_info.toString()}}</p>
                    <div class="row">
                        <div class="col-sm-7">
                            <div class="contact-info">                                
                                <ul class="contact-list">                                    
                                    <li *ngFor="let item of storesss">                                        
                                        <i class="icon-map-marker"></i>                                        
                                        {{item.WH_Web_Description}}
                                    </li>
                                    <li>
                                        <i class="icon-phone"></i>
                                        <a href="tel:#">{{phone}}</a>
                                    </li>
                                    <li>
                                        <i class="icon-envelope"></i>
                                        <a href="mailto:#">{{email}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-sm-5">
                            <div class="contact-info">
                                <h3>Working Hours</h3>

                                <ul class="contact-list">
                                    <li>
                                        <i class="icon-clock-o"></i>
                                        <span class="text-dark">{{working_days}}</span> <br>
                                    </li>
                                    <li>
                                        <i class="icon-calendar"></i>
                                        <span class="text-dark">{{weekend}}</span> <br>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h2 class="title mb-1">Got Any Questions?</h2>
                    <p class="mb-2">Use the form below to get in touch with the sales team</p>

                    <form action="#" class="contact-form mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label for="cname" class="sr-only">Name</label>
                                <input type="text" class="form-control" id="cname" placeholder="Name *" required>
                            </div>

                            <div class="col-sm-6">
                                <label for="cemail" class="sr-only">Email</label>
                                <input type="email" class="form-control" id="cemail" placeholder="Email *" required>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <label for="cphone" class="sr-only">Phone</label>
                                <input type="tel" class="form-control" id="cphone" placeholder="Phone">
                            </div>

                            <div class="col-sm-6">
                                <label for="csubject" class="sr-only">Subject</label>
                                <input type="text" class="form-control" id="csubject" placeholder="Subject">
                            </div>
                        </div>

                        <label for="cmessage" class="sr-only">Message</label>
                        <textarea class="form-control" cols="30" rows="4" id="cmessage" required
                            placeholder="Message *"></textarea>

                        <button type="submit" class="btn btn-outline-primary-2 btn-minwidth-sm" (click)="sendEmail($event)">
                            <span>SUBMIT</span>
                            <i class="icon-long-arrow-right"></i>
                        </button>
                    </form>
                </div>
            </div>

            
<!-- ************************ our stores section commented out ********************************-->
        <!--
            <div class="stores mb-4 mb-lg-5">
                <h2 class="title text-center mb-3">Our Stores</h2>

                <div class="row text-center">
                    <div class="col-lg-6">
                        <div class="store">
                            <div class="row">
                                <div class="col-sm-5 col-xl-6">
                                    <figure class="store-media mb-2 mb-lg-0">
                                        <img src="assets/images/stores/img-1.jpg" class="ml-auto mr-auto ml-sm-0"
                                            class="ml-auto mr-auto ml-sm-0" width="277" height="277" alt="image" />
                                    </figure>
                                </div>
                                <div class="col-sm-7 col-xl-6">
                                    <div class="store-content">
                                        <h3 class="store-title">Wall Street Plaza</h3>
                                        <address>88 Pine St, New York, NY 10005, USA</address>
                                        <div><a href="tel:#">+1 987-876-6543</a></div>

                                        <h4 class="store-subtitle">Store Hours:</h4>
                                        <div>Monday - Saturday 11am to 7pm</div>
                                        <div>Sunday 11am to 6pm</div>

                                        <a href="javascript:;" class="btn btn-link"><span>View
                                                Map</span><i class="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="store">
                            <div class="row">
                                <div class="col-sm-5 col-xl-6">
                                    <figure class="store-media mb-2 mb-lg-0">
                                        <img src="assets/images/stores/img-2.jpg" class="ml-auto mr-auto ml-sm-0"
                                            class="ml-auto mr-auto ml-sm-0" width="277" height="277" alt="image" />
                                    </figure>
                                </div>

                                <div class="col-sm-7 col-xl-6">
                                    <div class="store-content">
                                        <h3 class="store-title">One New York Plaza</h3>
                                        <address>88 Pine St, New York, NY 10005, USA</address>
                                        <div><a href="tel:#">+1 987-876-6543</a></div>

                                        <h4 class="store-subtitle">Store Hours:</h4>
                                        <div>Monday - Friday 9am to 8pm</div>
                                        <div>Saturday - 9am to 2pm</div>
                                        <div>Sunday - Closed</div>

                                        <a href="javascript:;" class="btn btn-link"><span>View
                                                Map</span><i class="icon-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        -->
        </div>

        <div id="map" *ngIf="apiService.showGoogleMap">
            <hr class="mt-4 mb-5">
            <div *ngIf="apiLoaded | async">
                <google-map [center]="center" [zoom]="16">
                    <map-marker [position]="center"></map-marker>
                </google-map>                
            </div>
        </div>
    </div>
</main>