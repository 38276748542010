<div class="container products deal">
    <div class="heading heading-flex heading-border mb-3" contentAnim aniName="blurIn">
        <div class="heading-left">
            <h2 class="title">New Products</h2>
        </div>
        <!-- **************** Heading right not needed ***********************************
        <div class="heading-right">
            <ul class="nav nav-pills nav-border-anim justify-content-center" role="tablist" tabClick>
                <li class="nav-item" *ngFor="let cat of cats; let index = index">
                    <a class="nav-link" [class.active]="index === 0" href="" role="tab"
                        (click)="nav.select( 'deal-' + cat )">{{ titles[cat] }}</a>
                </li>
            </ul>
        </div>
         -->
    </div>

    <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" [destroyOnHide]="false" >
        <li [ngbNavItem]="'deal-' + cat"  *ngFor="let cat of cats; let index=index"><!--*ngFor="let cat of cats; let index = index"> -->
            <ng-template ngbNavContent>                
                <owl-carousel-o carouselClasses="owl-simple carousel-equal-height carousel-with-shadow" 
                    [options]="customOptions" *ngIf="!loaded else loadedBlock">     
                    <!--
                    <ng-template carouselSlide [id]="index.toString()">                 
                        <div class="skel-pro" *ngFor="let i of products.length.toString()"></div>
                    </ng-template>
                -->
                    <div class="skel-pro" *ngFor="let i of [1,2,3,4,5,6]"></div>
                </owl-carousel-o>
                <ng-template #loadedBlock >
                    <owl-carousel-o carouselClasses="owl-simple carousel-equal-height carousel-with-shadow" [options]="customOptions" >
                        <ng-container *ngFor="let product of products" >
                            <ng-template carouselSlide [id]="index.toString()" class="owl-simple carousel-equal-height carousel-with-shadow" >
                                <molla-product-twelve [product]="product" > <!-- | catFilter : [cat] | slice: 0: 6"> -->
                                </molla-product-twelve>                                                    
                            </ng-template>                        
                        </ng-container>
                        
                    </owl-carousel-o>
                </ng-template>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>