<div id="cubeid" *ngIf="isLoaded" (click)="cubeClicked($event)">
    <div [ngClass]="{'cube': !isUnfolded, 'unfolded': isUnfolded}">
        <div  class="face back"><img  [src]="'assets/images/'+ apiService.img_folder + '/' + prods[0].PRODCODE + '.png'"></div>
        <div class="face front title">{{ prods[0].PRODDESC.substr(0,6) }}</div>
        <div class="face top"><img  [src]="'assets/images/'+ apiService.img_folder + '/' + prods[0].PRODCODE + '.png'"></div>
        <div class="face left title">{{ prods[0].PRODDESC.substr(0,6) }}</div>
        <div class="face bottom"><img  [src]="'assets/images/'+ apiService.img_folder + '/' + prods[0].PRODCODE + '.png'"></div>
        <div class="face right title">{{ prods[0].PRODDESC.substr(0,6) }}</div>
    </div>    
</div>

<!--
<div *ngIf="isUnfolded" [ngClass]="{'rectangle': isUnfolded}">
    <div class="fadein">New Products</div>
    <div class="carousel-container fadein">
        <div class="carousel-slide fadein" *ngFor="let product of prods">
          <img [src]="'assets/images/'+ apiService.img_folder + '/' + product.PRODCODE + '.png'" alt="{{ product.name }}">
          <div>{{ product.PRODDESC }}</div>
        </div>
    </div>
</div>
-->

<!--
<div *ngIf="isUnfolded" class="container products deal" [ngClass]="{'rectangle': isUnfolded}">
    <div class="heading heading-flex heading-border mb-3" contentAnim aniName="blurIn">
        <div class="heading-left">
            <h2 class="title">Brand New</h2>
        </div>        
    </div>

    <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" [destroyOnHide]="false" >
        <li [ngbNavItem]="'deal-'">
            <ng-template ngbNavContent>                
                <owl-carousel-o carouselClasses="owl-simple carousel-equal-height carousel-with-shadow" 
                    [options]="customOptions" >                     
                    <div class="skel-pro" *ngFor="let i of [1,2,3,4,5,6]"></div>
                </owl-carousel-o>
                <ng-template #loadedBlock >
                    <owl-carousel-o carouselClasses="owl-simple carousel-equal-height carousel-with-shadow" [options]="customOptions" >
                        <ng-container *ngFor="let product of prods; let index=index" >
                            <ng-template carouselSlide [id]="index.toString()" class="owl-simple carousel-equal-height carousel-with-shadow" >
                                <molla-product-twelve [product]="product" > 
                                </molla-product-twelve>                                                    
                            </ng-template>                        
                        </ng-container>
                        
                    </owl-carousel-o>
                </ng-template>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
-->
