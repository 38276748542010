<div [class]="'product-details-quantity ' + adClass">
    <div class="input-group input-spinner">
        <div class="input-group-prepend">
            <button class="btn btn-decrement btn-spinner button-style-left" (click)="decrement()" type="button">
                <i class="icon-minus"></i>
            </button>
        </div>
        <input type="number" class="form-control input-style" required placeholder="" min="1" [max]="max"
            [value]="current" (input)="changeCurrent($event)">
        <div class="input-group-append">
            <button  class="btn btn-increment btn-spinner button-style-right" (click)="increment()" type="button">
                <i class="icon-plus"></i>
            </button>
        </div>
    </div>
</div>