<div class="col-lg-6 center">
    <h2 class="title mb-1">Become a Partner</h2>
    <p class="mb-2">Become a retailer or distributor of our products and join our Royal Family providing your clients with our premium products with competitive prices.</p>

    <form action="#" class="contact-form mb-3">
        <div class="row">
            <div class="col-sm-6">
                <label for="cname" class="sr-only">Name</label>
                <input type="text" class="form-control" id="cname" placeholder="Name *" required>
            </div>

            <div class="col-sm-6">
                <label for="cemail" class="sr-only">Email</label>
                <input type="email" class="form-control" id="cemail" placeholder="Email *" required>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <label for="cphone" class="sr-only">Phone</label>
                <input type="tel" class="form-control" id="cphone" placeholder="Phone">
            </div>

            <div class="col-sm-6">
                <label for="csubject" class="sr-only">Subject</label>
                <input type="text" class="form-control" id="csubject" placeholder="Subject">
            </div>
        </div>

        <label for="cmessage" class="sr-only">Message</label>
        <textarea class="form-control" cols="30" rows="4" id="cmessage" required
            placeholder="Message *"></textarea>

        <button type="submit" class="btn btn-outline-primary-2 btn-minwidth-sm">
            <span>SUBMIT</span>
            <i class="icon-long-arrow-right"></i>
        </button>
    </form>
</div>
