
<main class="main home-page skeleton-body">

    <div *ngIf="isShowCubeAd">
        <molla-adscube></molla-adscube>
    </div>
    <!-- **************** Slider option with parameter FULL SCREEN  ***********************************-->
    <div class="intro-slider-container " *ngIf="apiService.fullscreenslider">        
        <owl-carousel-o carouselClasses="intro-slider" [options]="customOptions"  *ngIf="slidesLoaded">   <!-- intro-slider owl-simple -->         
            <ng-container *ngFor="let slide of slides"  class="intro-slide"><!--intro-slide-->
                <ng-template  carouselSlide [id]="slide.id"  class="owl-responsive"><!--contentAnim aniName="fadeInUpShorter" intro-content owl-responsive-->
                        <div class="intro-slide" style="background-image: url({{slide.image_src}});">
                            <div class="image-overlay intro-col col-auto offset-lg-3 intro-content" *ngIf="slide.display"><!--col-auto offset-lg-3 intro-col image-overlay intro-content-->
                                <h3 *ngIf="slide.display" class="intro-subtitle">{{slide.intro_subtitle}}</h3>
                                <h1 *ngIf="slide.display" class="intro-title">
                                    {{slide.intro_title1}}
                                    <br/>{{slide.intro_title2}}
                                    <span>
                                        <sup class="font-weight-light intro-titole3">{{slide.intro_title3}}</sup>
                                        <span class="text-primary">
                                            €{{slide.price}}
                                            <sup>,{{slide.price_decimal}}</sup>
                                        </span>
                                    </span>
                                </h1>
                                <a *ngIf="slide.display" href={{slide.link}} class="btn btn-outline-primary-2" >
                                    <span>{{slide.link_title}}</span>
                                    <i class="icon-long-arrow-right"></i>
                                </a>                                
                            </div>
                        </div>                                                   
                </ng-template>                
            </ng-container>        
        </owl-carousel-o>    
    </div>    

   
    
    

    <!-- **************** Slider option with parameter NOT FULL SCREEN  ***********************************-->
    <div class="row slider-row" *ngIf="!apiService.fullscreenslider">
        <div class="column grid-slider">
            <div class="intro-slider-container">
                <owl-carousel-o carouselClasses="owl-simple intro-slider" [options]="customOptions"  *ngIf="slidesLoaded">
                    <div *ngFor="let slide of slides" class="intro-slide"
                          style="background-image: url({{slide.image_src}}); background-color: #EDF2F0;">
                        <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                            <div class="row">
                                <div class="col-auto offset-lg-3 intro-col" *ngIf="slide.display">
                                    <h3 class="intro-subtitle">{{slide.intro_subtitle}}</h3>
        
                                    <h1 class="intro-title">
                                        {{slide.intro_title1}}
                                        <br />{{slide.intro_title2}}
                                        <span>
                                            <sup class="font-weight-light">{{slide.intro_title3}}</sup>
                                            <span class="text-primary">
                                                €{{slide.price}}
                                                <sup>,{{slide.price_decimal}}</sup>
                                            </span>
                                        </span>
                                    </h1>
                                    <a href={{slide.link}} class="btn btn-outline-primary-2">
                                        <span>{{slide.link_title}}</span>
                                        <i class="icon-long-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </owl-carousel-o>
            </div>
        </div>
        <!-- **************** Explore master categories section when show pictures = true ***********************************-->
        <div class="column master-cats-column"  *ngIf="apiService.show_master_categories_home_page && !apiService.fullscreenslider && apiService.showcatpictures">             
            <div class="grid-container">
                <div class="grid-container-popular-cats" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: popular.CATID, title: popular.Description}">                           
                        <!-- if there is an image then show the brands image -->      
                        <span>
                            <img src="{{popular_mster_cats_img_path}}{{popular.code.trim()}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="106px" height="90px">
                        </span>                                      
                    </a>
                    <h6 class="">{{popular.Description}}</h6>
                </div>
            </div>            
        </div>
        <!-- **************** Explore master categories section when show pictures = false (show text only) ***********************************-->
        <div class="column" *ngIf="apiService.show_master_categories_home_page && !apiService.fullscreenslider && !apiService.showcatpictures">             
            <div class="grid-container">
                <div class="grid-button" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: popular.CATID, title: popular.Description}">                                                   
                        <!--<span>
                            <img src="{{popular_cats_img_path}}{{popular.CATID}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="150" height="130">
                        </span>-->
                    </a>
                    <h6 class="category-title">{{popular.Description}}</h6>
                </div>
            </div>            
        </div>
        <!-- **************** Explore popular categories section show pictures = true  ***********************************-->
        <div class="column master-cats-column" *ngIf="!apiService.show_master_categories_home_page && !apiService.fullscreenslider && apiService.showcatpictures">
            <div class="grid-container">                      
                <div class="grid-container-popular-cats" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{subcategory: popular.CATID}">
                        <figure>
                            <span>
                                <img src="{{popular_cats_img_path}}{{popular.code}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="108px" height="90px">
                            </span>
                        </figure>
                        <h6 class="">{{popular.Description}}</h6>
                    </a>
                </div>
            </div>
        </div>        
        <!-- **************** Explore popular categories section show picture = false (text only)  ***********************************-->
        <div class="column" *ngIf="!apiService.show_master_categories_home_page && !apiService.fullscreenslider && !apiService.showcatpictures">
            <div class="grid-container">                      
                <div class="grid-button" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{subcategory: popular.CATID}">
                        <!--<figure>
                            <span>
                                <img src="{{popular_cats_img_path}}{{popular.CATID}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="108px" height="90px">
                            </span>
                        </figure>-->
                        <h6 class="category-title">{{popular.Description}}</h6>
                    </a>
                </div>
            </div>
        </div>        
    </div><!-- end of row-->           

    <div class="mb-4"></div>

    
    <!-- **************** Explore master categories section for parameter full screen ***********************************-->
    <div class="container" *ngIf="apiService.show_master_categories_home_page &&  apiService.fullscreenslider">
        <div class="heading heading-flex heading-border mb-3" contentAnim aniName="blurIn">
            <div class="heading-left">
                <h2 class="title" contentAnim aniName="blurIn">Explore Popular Categories</h2>
            </div>
        </div>        
        <!--********** explore master categories section for full screen and showing pictures **********-->
        <div class="cat-blocks-container" *ngIf="apiService.showcatpictures">
            <div class="row">                
                <div class="col-6 col-sm-4 col-lg-2" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: popular.CATID, title: popular.Description}" class="cat-block" >                        
                        <figure>
                            <span>
                                <img src="{{popular_mster_cats_img_path}}{{popular.code.trim()}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="106px" height="90px">
                            </span>                            
                        </figure>                          
                        <h6 class="">{{popular.Description}}</h6>
                    </a>
                </div>                                             
            </div>
        </div>
        <!--********** explore master categories section for full screen and NOT showing pictures **********-->
        <div class="cat-blocks-container" *ngIf="!apiService.showcatpictures">
            <div class="row">                
                <div class="col-6 col-sm-4 col-lg-2" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: popular.CATID, title: popular.Description}" class="cat-block brand-button" >                        
                        <!--<figure>
                            <span>
                                <img src="{{popular_mster_cats_img_path}}{{popular.code.trim()}}.png" onError="this.onerror=null; this.remove();" alt="{{popular.Description}}" width="106px" height="90px">
                            </span>                            
                        </figure>-->
                        <h6 class="category-title">{{popular.Description}}</h6>
                    </a>
                </div>                                             
            </div>
        </div>
    </div>
   
    <div [ngStyle]="{background: 'url(' + catImgPath + ')'}">
    <!-- **************** Explore popular categories section for parameter full screen ***********************************-->
    <div class="container" *ngIf="!apiService.show_master_categories_home_page && apiService.fullscreenslider">
        <h2 class="title text-center mb-2" contentAnim aniName="blurIn">Explore Popular Categories</h2>
        <!--********** explore popular categories section for full screen and showing pictures **********-->
        <div class="cat-blocks-container" *ngIf="apiService.showcatpictures">
            <div class="row">
                <div class="col-6 col-sm-4 col-lg-2" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{subcategory: popular.CATID}" class="cat-block" >
                        <figure>
                            <span>
                                <img src="{{popular_cats_img_path}}{{popular.code}}.png" alt="{{popular.Description}}" width="150" height="130">
                            </span>
                        </figure>
                        <h6 class="">{{popular.Description}}</h6>
                    </a>
                </div>                
            </div>
        </div>
        <!--********** explore popular categories section for full screen and NOT showing pictures **********-->
        <div class="cat-blocks-container" *ngIf="!apiService.showcatpictures">
            <div class="row">
                <div class="col-6 col-sm-4 col-lg-2" *ngFor="let popular of popular_cats">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{subcategory: popular.CATID}" class="cat-block brand-button" >
                        <!--<figure>
                            <span>
                                <img src="{{popular_cats_img_path}}{{popular.code}}.png" alt="{{popular.Description}}" width="150" height="130">
                            </span>
                        </figure>-->
                        <h6 class="category-title">{{popular.Description}}</h6>
                    </a>
                </div>                
            </div>
        </div>
    </div>
    </div>
    <div class="mb-2"></div>

<!--************************** Section for ads with images not needed *************************************
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="banner banner-1 banner-overlay" style="background-color: #ebebeb;" contentAnim
                    aniName="fadeInLeft">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image src="assets/images/home/banners/banner-1.jpg" alt="banner" width="277"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle text-white">
                            <a href="#">Weekend Sale</a>
                        </h4>

                        <h3 class="banner-title text-white">
                            <a href="#">
                                Lighting
                                <br />& Accessories
                                <br />
                                <span>25% off</span>
                            </a>
                        </h3>

                        <a routerLink="/shop/sidebar/3cols" class="banner-link">
                            Shop Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 order-lg-last">
                <div class="banner banner-3 banner-overlay" style="background-color: #465d55;" contentAnim
                    aniName="fadeInRight">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image src="assets/images/home/banners/banner-3.jpg" alt="banner" width="277"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle text-white">
                            <a href="#">Smart Offer</a>
                        </h4>

                        <h3 class="banner-title text-white">
                            <a href="#">
                                Anniversary
                                <br />Special
                                <br />
                                <span>15% off</span>
                            </a>
                        </h3>

                        <a routerLink="/shop/sidebar/list" class="banner-link">
                            Shop Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner banner-2 banner-overlay" style="background-color: #d28679;" contentAnim
                    aniName="fadeInUpShorter">
                    <a routerLink="/shop/sidebar/3cols">
                        <molla-image src="assets/images/home/banners/banner-2.jpg" alt="banner" width="576"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle text-white d-none d-sm-block">
                            <a href="#">Amazing Value</a>
                        </h4>

                        <h3 class="banner-title text-white">
                            <a href="#">
                                Clothes Trending
                                <br />Spring Collection 2021
                                <br />
                                <span>from €12,99</span>
                            </a>
                        </h3>

                        <a routerLink="/shop/sidebar/3cols" class="banner-link">
                            Discover Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->

     <!-- **************** New Products Section ***********************************-->    
     <div class="bg-light pt-3 pb-5">
        <molla-new-collection [products]="new_products" [loaded]="new_loaded">
        </molla-new-collection>
    </div>
    

    <!-- **************** Hot Deal Section ***********************************-->
    
    <div class="bg-light pt-3 pb-5">
        <molla-deal-collection [products]="product" [loaded]="loaded">
        </molla-deal-collection>
    </div>
    <div class="mb-3"></div>
    
      
    <!-- **************** SHOP BY BRAND  ***********************************-->    
    
  <div class="container" *ngIf="brands.length>0">
    <div class="heading heading-flex heading-border mb-3" contentAnim aniName="blurIn">
        <div class="heading-left">
            <h2 class="title" contentAnim aniName="blurIn">Shop by Brand</h2>
        </div>      
        <div class="heading-right">
           <a routerLink="/">Show all Brands</a>
        </div> 
    </div>

    <div class="cat-blocks-container" *ngIf="apiService.showbrandpictures"> 
        <div class="row">
            <div class="col-6 col-sm-4 col-lg-2" *ngFor="let brand of brands">
                <a routerLink="/shop/sidebar/3cols" [queryParams]="{brand: brand.Brand_Id, brandName: brand.Brand_name}" class="" >
                    <figure>
                        <span>
                            <img src="{{brands_img_path}}{{brand.Brand_code}}.png" onError="this.onerror=null; this.remove();" 
                               alt="{{brand.Brand_name}}" class="brand-image-size">
                        </span>
                    </figure>                   
                </a>
            </div>
        </div>
    </div> 


    <!--*********************** Show brands without an image *************************-->
    <div class="cat-blocks-container" *ngIf="!apiService.showbrandpictures">
        <div class="row">
            <div class="col-6 col-sm-4 col-lg-2" *ngFor="let brand of brands">
                <a routerLink="/shop/sidebar/3cols" [queryParams]="{brand: brand.Brand_Id, brandName: brand.Brand_name}" class="brand-button" >
                    <figure>
                        <span>
                            <img src="{{brands_img_path}}{{brand.Brand_code}}.png" onError="this.onerror=null; this.remove();" alt="{{brand.Brand_name}}" class="brand-image"> 
                        </span>
                    </figure>
                   <h6 class="brands-title">{{brand.Brand_name}}</h6>
                </a>
            </div>
        </div>
    </div>   

    <!-- Google Reviews Section -->
    <div class="heading heading-flex heading-border mb-3 reviews-title" contentAnim aniName="blurIn" *ngIf="showGooglReviews">
        <div class="heading-left">
            <h2 class="title" contentAnim aniName="blurIn">Google Reviews</h2>
        </div>      
        <div class="heading-right">
           <a href="{{greviews.result.url}}" target="_blank">Show more</a>           
        </div> 
    </div>
    <div class="" *ngIf="showGooglReviews">
        <div class="review-row">
            <div class="review-column" *ngFor="let review of greviews.result.reviews"><!--col-sm-4 col-lg-2-->
                <figure class="review-content">
                    <span>
                        <img src="{{review.profile_photo_url.toString()}}" onError="this.onerror=null; this.remove();" alt="Profile Picture" class="reviews-image"> 
                        <div> {{review.author_name}}</div>
                        <span *ngFor="let i of  calcfullstars(review.rating)">
                            <span class="star">★</span>                                                        
                        </span>
                        <span *ngFor="let i of  calcemptystars(review.rating)">
                            <span class="star">☆</span>                        
                        </span>
                        <div class="review-text">
                            {{review.text}}
                        </div>                        
                    </span>
                </figure>                      
            </div>
        </div>
    </div>
  </div>

    <div class="mb-3"></div>

    <div class="mb-3"></div>

   

    <!-- **************** Ads section not needed ***********************************
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner banner-4 banner-overlay banner-overlay-light" style="background-color: #ebebeb;">
                    <a routerLink="/shop/sidebar/3cols">
                        <img src="assets/images/home/banners/banner-4.jpg" alt="Banner" width="575" height="260">
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle">
                            <a routerLink="/shop/sidebar/3cols">Spring Sale is Coming</a>
                        </h4>

                        <h3 class="banner-title">
                            <a routerLink="/shop/sidebar/3cols">
                                All Smart Watches
                                <br />Discount
                                <br />
                                <span class="text-primary">15% off</span>
                            </a>
                        </h3>

                        <a routerLink="/shop/sidebar/list" class="banner-link banner-link-dark">Discover Now<i
                                class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner banner-5 banner-overlay" style="background-color: #808a95;">
                    <a routerLink="/shop/sidebar/3cols">
                        <img src="assets/images/home/banners/banner-5.png" alt="Banner" width="575" height="260">
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle text-white">
                            <a routerLink="/shop/sidebar/4cols">Amazing Value</a>
                        </h4>

                        <h3 class="banner-title text-white">
                            <a routerLink="/shop/sidebar/4cols">
                                Headphones Trending
                                <br />JBL Harman
                                <br />
                                <span>from €59,99</span>
                            </a>
                        </h3>

                        <a routerLink="/shop/sidebar/list" class="banner-link">Discover Now<i
                                class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
    <div class="mb-1"></div>     
  
</main>