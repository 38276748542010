import { Component, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';

import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
	selector: 'molla-mobile-button',
	templateUrl: './mobile-button.component.html',
	styleUrls: ['./mobile-button.component.scss']
})

export class MobileButtonComponent implements OnInit {

	public isNotIphone:boolean;

	constructor(public utils: UtilsService, public render: Renderer2, private apiService: ApiService) { }
	

	ngOnInit(): void {
		if(this.apiService.isMobile && this.apiService.isIOS == "iOS")
			this.isNotIphone = false;
		else
			this.isNotIphone = true;

		//console.log("*****malakies ***** : " ,this.isNotIphone, this.apiService.isMobile, this.apiService.isIOS);
	}

	toggleMenu(event: any) {
		const body = document.querySelector('body');
		const html = document.querySelector('html');					
		
		if (body.classList.contains('mmenu-active') || event.target.classList.contains('mobile-menu-overlay') || body.classList.contains('mmenu-active-iphone')) {
			this.render.removeClass(body, 'mmenu-active');
			this.render.removeClass(body, 'mmenu-active-iphone');
			this.render.removeAttribute(html, 'style')
		} else if(this.isNotIphone) {
			this.render.addClass(body, 'mmenu-active');
			this.render.setStyle(html, 'overflow-x', 'hidden');			
		}
		else{
			this.render.addClass(body, 'mmenu-active-iphone');
			this.render.setStyle(html, 'overflow-x', 'hidden');
		}
	}
}
