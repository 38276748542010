import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { domainMetaMap, domainAboutPageMetaMap, domainContactPageMetaMap, domainProductsPageMetaMap, domainPartnerPageMetaMap } from './meta-config.js';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta, private apiService:ApiService, private titleService:Title) {}

  updateMetaTags(domainName: string) {
    const metaTags = domainMetaMap[domainName];

    if (metaTags) {     
      this.titleService.setTitle(metaTags.title);    
      this.meta.updateTag({ name: 'title', content: metaTags.title });
      document.getElementById('title').setAttribute("content",this.apiService.company_name + " " + metaTags.title); 
      //console.log(document.getElementById("title"));
      
      this.meta.updateTag({ name: 'description', content: metaTags.description });
      document.getElementById('description').setAttribute("content",metaTags.description);
      //console.log(document.getElementById("description"));
      
      this.meta.updateTag({ name: 'keywords', content: metaTags.keywords });
      document.getElementById('keywords').setAttribute("content",metaTags.keywords);
      //console.log(document.getElementById("keywords"));
      
      this.meta.updateTag({ name: 'company', content: metaTags.company });
      document.getElementById('company').setAttribute("content",metaTags.company);
      //console.log(document.getElementById("company"));
      
    }
  }

  updatePageMetaTags(domainName:string,page:string) {
    var metaTags:any =[]; //= domainMetaMap[domainName];
    switch (page){
      case ('About'):{
        metaTags = domainAboutPageMetaMap[domainName];
        break;
      }
      case ('Contact'):{
        metaTags = domainContactPageMetaMap[domainName];
        break;
      }
      case ('Products'):{
        metaTags = domainProductsPageMetaMap[domainName];
        break;
      }
      case ('Partner'):{
        metaTags = domainPartnerPageMetaMap[domainName];
        break;
      }
    }
    

    this.meta.updateTag({ name: 'title', content: metaTags.title });
    document.getElementById('title').setAttribute("content",this.apiService.company_name + " " + metaTags.title); 
    //console.log(document.getElementById("title"));
    
    this.meta.updateTag({ name: 'description', content: metaTags.description });
    document.getElementById('description').setAttribute("content",metaTags.description);
    //console.log(document.getElementById("description"));
    
    this.meta.updateTag({ name: 'keywords', content: metaTags.keywords });
    document.getElementById('keywords').setAttribute("content",metaTags.keywords);
    //console.log(document.getElementById("keywords"));        
  }

  addTags(domainName: string){
    const metaTags = domainMetaMap[domainName];

    if (metaTags) {
      this.meta.addTags([
        { name: 'keywords', content: metaTags.keywords, },
        { name: 'description', content: metaTags.description },
        { name: 'title', content: metaTags.title },        
        { name: 'company', content: metaTags.company },
        { name: 'author', content: 'EuroTech L&M Ltd' },                
        { charset: 'UTF-8' },
        { name: 'robots', content: 'index, follow' }
      ]);
    }          
  }
  

  public getMetaTags(){
    return this.meta;
  }
}
