<!--
<div class="video-modal">
    <iframe class="mfp-iframe" [src]="videoURL" frameBorder="0" allowFullScreen=""
        title="youtube"></iframe>
    <button type="button" class="close" (click)="closeModal()"><span><i
                class="icon-close"></i></span></button>
</div>
-->

<div class="video-modal">
    <span class="mfp-iframe" [innerHTML]="trustedURLframe"></span>
    <button type="button" class="close" (click)="closeModal()"><span><i
                class="icon-close"></i></span></button>
</div>