<header class="header header-10 header-intro-clearance">
    <div class="header-top">
        <div [class]="containerClass">
            <div class="header-left">
                <a href="tel:#">
                    <i class="icon-phone"></i>{{apiService.call_info}}
                </a>
            </div>

            <div class="header-right">

                <ul class="top-menu">
                    <li>
                        <a href="javascript:;">Sign in</a>
                        <ul>
                            <!-- *********** not needed for the moment **************
                            <li>
                                <div class="header-dropdown">
                                    <a href="javascript:;">Eur</a>
                                    <div class="header-menu">
                                        <ul>
                                            <li><a href="javascript:;">Eur</a></li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="header-dropdown">
                                    <a href="javascript:;">English</a>
                                    <div class="header-menu">
                                        <ul>
                                            <li><a href="javascript:;">English</a></li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            -->
                            <li class="login" *ngIf="apiService.userInfo.length == 0"><a href="javascript:;" (click)="showLoginModal($event)">Sign in / Sign up</a></li>
                            <li class="login" *ngIf="apiService.userInfo.length > 0"><a [routerLink]="['/shop/dashboard']">My Account</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="header-middle">
        <div [class]="containerClass">
            <div class="header-left">
                <molla-mobile-button></molla-mobile-button>

                <a routerLink="/" class="logo">
                    <img src="assets/images/{{apiService.img_folder}}/logo.png" alt="Logo" width="205" height="127" class="logo-mobile">  <!-- width="105" height="27" -->
                </a>
            </div>

            <div class="header-center">
                <molla-header-search></molla-header-search>
            </div>

            <div class="header-right">
                <div class="header-dropdown-link">
                    <!--********** not needed ************
                    <molla-compare-menu></molla-compare-menu>
                    -->
                    <!-- Reward Points goes here-->
                    <molla-reward-menu></molla-reward-menu>
                    <molla-wishlist-menu></molla-wishlist-menu>                    
                    <molla-cart-menu></molla-cart-menu>
                </div>
            </div>
        </div>
    </div>

    <div class="sticky-wrapper" [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
        <div class="header-bottom sticky-header" [class.fixed]="utilsService.isSticky">
            <div [ngClass]="containerClass">
                <div class="header-left">
                    <molla-category-menu></molla-category-menu>
                </div>

                <div class="header-center-main-menu">
                    <molla-main-menu></molla-main-menu>
                </div>
                <!-- *************** not needed for the moment ***************
                <div class="header-right overflow-hidden">
                    <i class="la la-lightbulb-o"></i>
                    <p>Clearance Up to 30% Off</p>
                </div>
                -->
            </div>
        </div>
    </div>
</header>