export const domainMetaMap = {
    'www.royalvape.com.cy': {
      title: 'Royal Vape | Electronic Cigarette | Vape Shop | Free Delivery | E-Liquids',
      description: 'Royal vape is the most luxurious vape shop company in Cyprus with 5 prime locations in nicosia and free delivery online shop.',
      keywords: 'Vape , vape shop, Iqos,terea,iluma,heated tobacco,iluma prime,iluma one,electronic cigarettes cyprus, vape shop cyprus, kiwi vape , pod , disposables, hookah, shisha, shisha cyprus, bongs, grinders, scales, vaporizer, lost mary, elfbar, vapengin, smok, vaporesso , vape cyprus , ivg , uwell , aspire, lost vape, ecig cyprus, Geekvape, disposable vape, vape store, vape batteries, vape juice , vape liquid, puffs vape, Royal vape',    
      company: 'Royal Vape Ltd',  
      placeid: 'ChIJ99Geh5AZ3hQRQRUAWWMO9dA'
      // Add more meta tag values as needed
    },
    'www.titosecig.com': {
      title: 'TITOS VAPE SHOP was established in 2011 and our purpose was to provide vapers with premium e-juices and best quality e-cigarette devices from around the globe.This is an online vape shop located in Limassol Cyprus, where you can buy online vape,flavors and accessories.',
      description: 'We set high standards for our vape products in Limassol Cyprus as well as online shipping, and having competitive prices for vaping and accessories in the wholesale and retail market, makes us easy to approach in the high demanding vape market of the vaping industry for any type of vaping with free shipping in Cyprus.',
      keywords: 'eshop, shopping online, vape cyprus, free shipping, flavors, vaping accessories, titos vape, vape limassol, vape cyprus',
      company: 'The Vape Shop',
      placeid: 'ChIJd-Yo9EMz5xQRTX8GtHsGg-I'
      // Add more meta tag values as needed
    },
    'royaltradingcy.com': {
      title: 'Royal Trading Cyprus. Wholeslae store. We provide vapers with premium e-juices and best quality e-cigarette devices from around the globe.This is an online vape shop located in Limassol Cyprus, where you can buy online vape,flavors and accessories.',
      description: 'We set high standards for our vape products online shipping, and having competitive prices for vaping and accessories in the wholesale and retail market.',
      keywords: 'eshop, shopping online, royal trading, flavors, vaping accessories, vape limassol, vape cyprus',
      company: 'Royal Trading Cyprus',
      placeid: ''
      // Add more meta tag values as needed
    },
    'localhost': {
      title: 'This is localhost | Test | for vapes',
      description: 'This is yet another website.',
      keywords: 'eshop, shopping, online, vape cyprus, free shipping, flavors, vaping, accessories, local host',
      company: 'The Local Host',
      placeid: ''
      // Add more meta tag values as needed
    },
    // Add more domain names and their meta tag values as needed
  };
  
  export const domainAboutPageMetaMap = {
    'localhost':{
      title:'About us',
      description: 'This is the localhost about use page.',
      keywords: 'localhost, about, about us',
      company: 'This is the company TEST',

    },
    'www.royalvape.com.cy':{
      title:'About us',
      description: 'Royal Vape is the No1 vape supplier and manufacturer in Cyprus. We offer the biggest variety of vaping products.',
      keywords: 'royal,royal vape, supplier,manufactarer, about, about us',
      company: 'Royal Vape',

    },
    'www.titosecig.com':{
      title:'About us',
      description: 'We set high standards for our products, and having competitive prices in the wholesale and retail market makes us easy to approach in the high demanding market of the vaping industry.',
      keywords: 'titos, vape, shop, vape shop, about us',
      company: 'Titos Vape Shop',

    },
    'royaltradingcy.com':{
      title:'About us',
      description: 'We set high standards for our products, and having competitive prices in the wholesale and retail market makes us easy to approach in the high demanding market of the vaping industry.',
      keywords: 'royal trading, vape, shop, about us',
      company: 'Royal Trading Cyprus',

    }
  }

  export const domainContactPageMetaMap = {
    'localhost':{
      title:'Contact us',
      description: 'This is the localhost about use page.',
      keywords: 'localhost, contact us',
      company: 'This is the company TEST',

    },
    'www.royalvape.com.cy':{
      title:'Contact us',
      description: 'Please feel free to contact us in any form and shape suits your needs. You can visit our store, give us a call, email or even fill the form located in this page.',
      keywords: 'contact, contact us,royalvape, royal, vape',
      company: 'Royal Vape',

    },
    'www.titosecig.com':{
      title:'Contact us',
      description: 'Use the form located in the contact us page to get in touch with us.',
      keywords: 'titos, vape, shop, vape shop, contact us',
      company: 'Titos Vape Shop',

    },
    'royaltradingcy.com':{
      title:'Contact us',
      description: 'Use the form located in the contact us page to get in touch with us.',
      keywords: 'royal trading, vape, shop, contact us',
      company: 'Royal Trading Cyprus',

    }
  }

  export const domainProductsPageMetaMap = {
    'localhost':{
      title:'All Products',
      description: 'This is the localhost all products page.',
      keywords: 'localhost, all products',
      company: 'This is the company TEST',

    },
    'www.royalvape.com.cy':{
      title:'All Products',
      description: 'Here you can find all the products offered online in order to make your purchase. We offer a wide range of vape products as well as accessories and flavors.',
      keywords: 'products,vape, ecommerce,buy, online, royal, vape',
      company: 'Royal Vape',

    },
    'www.titosecig.com':{
      title:'All Products',
      description: 'Here you can find all the products offered online in order to make your purchase. We offer a wide range of vape products as well as accessories and flavors.',
      keywords: 'titos, vape, shop, products, buy, online',
      company: 'Titos Vape Shop',

    },
    'royaltradingcy.com':{
      title:'All Products',
      description: 'Here you can find all the products offered online in order to make your purchase. We offer a wide range of vape products as well as accessories and flavors.',
      keywords: 'royal trading, vape, shop, products, buy, online',
      company: 'Royal Trading Cyprus',

    }
  }

  export const domainPartnerPageMetaMap = {
    'localhost':{
      title:'Becoma a Partner',
      description: 'This is the localhost Partner page.',
      keywords: 'localhost, Partner',
      company: 'This is the company TEST',

    },
    'www.royalvape.com.cy':{
      title:'Become a Partner',
      description: 'Become a retailer or distributor of our products and join our Royal Family providing your clients with our premium products with competitive prices.',
      keywords: 'partner,vape, retail, royal,buy',
      company: 'Royal Vape',

    },
    'www.titosecig.com':{
      title:'Become a Partner',
      description: 'Become a retailer or distributor of our products and join our Royal Family providing your clients with our premium products with competitive prices.',
      keywords: 'titos, vape, shop, retail, buy, partner',
      company: 'Titos Vape Shop',

    },
    'royaltradingcy.com':{
      title:'Become a Partner',
      description: 'Become a retailer or distributor of our products and join our Royal Family providing your clients with our premium products with competitive prices.',
      keywords: 'royal trading, vape, shop, retail, buy, partner',
      company: 'Royal Trading Cyprus',

    }
  }