import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})

export class StoreService {
    constructor(@Inject(Store) private store: Store<any>) {
        store.subscribe(items => {
            if (typeof window !== 'undefined') 
                localStorage.setItem('molla', JSON.stringify(items));
        })
    }
}