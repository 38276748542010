<footer class="footer footer-2">
    <div class="container">
        <hr class="mb-0 mt-0">
    </div>
    <div class="footer-middle border-0">
        <div [ngClass]="containerClass">
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <div class="widget widget-about">
                        <img src="assets/images/{{apiService.img_folder}}/logo-footer.png" class="footer-logo" alt="Footer Logo" width="150"
                            height="27">
                        <p>{{footer_text}}</p>

                        <div class="widget-about-info">
                            <div class="row">
                                <div class="col-sm-6 col-md-4">
                                    <span class="widget-about-title">Got Question? Call us</span>
                                    <a href="tel:{{apiService.call_info}}">{{apiService.call_info}}</a>
                                </div>
                                <div class="col-sm-6 col-md-8">
                                    <span class="widget-about-title">Payment Method</span>
                                    <figure class="footer-payments">
                                        <img src="assets/images/payments.png" alt="Payment methods" width="272"
                                            height="20" class="footer-payments"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">Information</h4>

                        <ul class="widget-list">
                            <li><a routerLink="pages/about">About Us</a></li>
                            <!--<li><a href="javascript:;">How to shop</a></li>-->
                            <!--<li><a routerLink="pages/faq">FAQ</a></li>-->
                            <li><a routerLink="pages/contact">Contact us</a></li>
                            <!--<li><a routerLink="pages/login">Log in</a></li>-->
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">Customer Service</h4>

                        <ul class="widget-list">
                            <!--<li><a href="javascript:;">Payment Methods</a></li>-->
                            <!--<li><a href="javascript:;">Money-back guarantee!</a></li>-->
                            <li><a routerLink="pages/returns">Returns</a></li>
                            <!--<li><a href="javascript:;">Shipping</a></li>-->
                            <li><a routerLink="pages/terms">Terms and conditions</a></li>
                            <li><a routerLink="pages/privacy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">My Account</h4>

                        <ul class="widget-list">
                            <!--<li><a routerLink="/shop/dashboard">Sign In</a></li>-->
                            <li><a routerLink="/shop/cart">View Cart</a></li>
                            <li><a routerLink="/shop/wishlist">My Wishlist</a></li>
                            <!--<li><a href="javascript:;">Track My Order</a></li>-->
                            <!--<li><a href="javascript:;">Help</a></li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div [ngClass]="containerClass">
            <p class="footer-copyright">Copyright © {{ year }}.Powered by <a href="https://eurotechcy.com" target="_blank">EuroTech Software</a>. All Rights Reserved.</p>
            <ul class="footer-menu">
                <li>(v{{version}})</li>
                <li><a routerLink="/pages/terms">Terms Of Use</a></li>
                <li><a routerLink="/pages/privacy">Privacy Policy</a></li>                
            </ul>

            <div class="social-icons social-icons-color">
                <span class="social-label">Social Media</span>
                <a *ngIf="this.apiService.facebook.trim()!= ''" href={{this.apiService.facebook}} class="social-icon social-facebook" title="Facebook" target="_blank"><i
                        class="icon-facebook-f"></i></a>
                <a *ngIf="this.apiService.twitter.trim() != '' " href={{this.apiService.twitter}} class="social-icon social-twitter" title="Twitter" target="_blank"><i
                        class="icon-twitter"></i></a>
                <a *ngIf="this.apiService.instagram.trim() != ''" href={{this.apiService.instagram}} class="social-icon social-instagram" title="Instagram" target="_blank"><i
                        class="icon-instagram"></i></a>
                <a *ngIf="this.apiService.youtube.trim() != ''" href={{this.apiService.youtube}} class="social-icon social-youtube" title="Youtube" target="_blank"><i
                        class="icon-youtube"></i></a>
                <a *ngIf="this.apiService.tiktok.trim() != ''" href={{this.apiService.tiktok}} class="social-icon" title="TikTok" target="_blank">
                    <img src="/assets/images/tiktok.png" width="15px;"></a>
                <!-- <a href="javascript:;" class="social-icon social-pinterest" title="Pinterest"><i
                        class="icon-pinterest"></i></a> -->
            </div>
        </div>
    </div>    
    <div class="mb-10" *ngIf="isBottomSticky"></div>
</footer>