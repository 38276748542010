import { ComponentFactoryResolver, Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
//import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { Product } from '../classes/product';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { shopData } from '../../pages/shop/shared/data';
import Swal from 'sweetalert2';
import { catchError, isEmpty } from 'rxjs/operators';
import { CartService } from './cart.service';
import { domainMetaMap } from '../countries';
import { request } from 'node:http';





@Injectable({
	providedIn: 'root'
})

export class ApiService {	
	
	constructor(private http: HttpClient, private cartService: CartService) {
	}

	//#region global variables	
	@Input() products:Product[];
	//public conn:string = ""	;//&dbConnString=VAPES_DB_LOCAL	
	public call_info:string;
	public website_url:string;
	public masterCats = [];
 	public cats = [];
    public categoriesWithSubCategories = [];
	public db_conn_string:string='';
	public img_folder:string='';
 	public viva_auth:string='';
	public companyName=''
 	public working_hours:string='';
 	public working_hours_monday:string='';
 	public working_hours_tuesday:string='';
 	public working_hours_wednesday:string='';
 	public working_hours_thursday:string='';
 	public working_hours_friday:string='';
 	public working_hours_saturday:string='';
 	public working_hours_sunday:string='';
 	public paypal_auth:string='';
	public iqtbl_api_url_based_on_client_domain:string='';
	public slides = [];		
	public page_number=1;	
	public Customer_VAT_On_Mobile='';	
	public Order_Mobile_Show_On_Pos='';
	public userInfo=[];
	public usersOrders=[];
	public shippingCost:number=0.00;
	public dtShipping =[];
	public shippingDesc=''
	public shippingId='';
	public shippingLimit;
	public quickViewProdId='';
	public company_name = '';
	public free_delivery;
	public show_master_categories_home_page;
	public warehouseid = '-1';
	public fullscreenslider;
	public showcatpictures: boolean;
	public showbrandpictures: boolean;
	public notify_arr=[];
	public showStock: boolean;
	public imageURLmainPath:string;
	public vision:string;
	public mission:string;
	public who_we_are:string;
	public imageName:string;
	public imageURL:string
	public lat:number;
	public long:number;
	public phone;
	public address;
	public email;
	public working_hoursContact;
	public working_daysContact;
	public weekendContact;
	public contact_info;
	public imageNameContact;	
	public title1;
	public title2;
	public title3;
	public plus18:boolean = false;
	public productDeliveryInfo:string='';
	public productCancellationReturnInfo:string='';
	public selectedRelated:Product;
	public ipBasedCountry:string = '';
	public showGoogleMap:boolean;
	public showPayCashOnDelivery:boolean;
	public showPayWithCreditCard:boolean;	
	public stores:any;
	public showPayBankTransfer:boolean;
	public googleAnalyticsID:string='';
	public twitter:string='';
	public facebook:string='';
	public instagram:string='';
	public youtube:string='';
	public shareProductConnStr:string='';
	public showSignup:boolean;
	public showSignin:boolean;
	public home_cats_background_img='';
	public showVivaWallet:boolean;
	public baseURL;
	public viva_source_code:string;
	public company_email:string;
	public tiktok:string='';
	public catsLinkFromParams = '/shop/sidebar/3cols';
	public showPoints:boolean = false;	
	public redeemPoints:number = 0.00;
	public isMobile:boolean;
	public isIOS:string="";
	public sessionExpire;
	public cookieExpire;
	public vattext:string='';

	private readonly api_key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJFdXJvdGVjaCBDeXBydXMgTHRkIiwiYXVkIjoib3JkZXJzIiwiaWF0IjoxNTE2MjM5MDIyfQ.rwyXPAq90D9qCCplPc-vQme-dAU_KUEunRJpNET2cE4";

	//#endregion

	/**
	 * Get Products
	 */
	public fetchShopData(params: any, perPage: number, initial = 'shop'): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		

		let temp = initial;
	
		if (!initial.includes('?')) {
			temp += '?';
		}

		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		if (!params.page) {
			temp += 'page=1';
			this.page_number = 1;
		}
		//added in order to update the products showing 
		else{
			this.page_number = params.page;
		}

		if (!params.perPage) {
			temp += '&perPage=' + perPage;
		}

		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			temp += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}	

		temp += '&demo=' + environment.demo;
		//add our API's controller
		if (temp.includes('shop')){
			temp=temp.replace('shop','FindShopProducts');			
		}
	
		temp += this.db_conn_string;	
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/${temp}`,requestOptions);
	}

	public fetchShopDataPromise(params: any, perPage: number, initial = 'shop'){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		
		let temp = initial;
	
		if (!initial.includes('?')) {
			temp += '?';
		}

		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		if (!params.page) {
			temp += 'page=1';
			this.page_number = 1;
		}
		//added in order to update the products showing 
		else{
			this.page_number = params.page;
		}

		if (!params.perPage) {
			if(temp[temp.length-1] === '&')
				temp += 'perPage=' + perPage;
			else
				temp += '&perPage=' + perPage;
		}

		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			temp += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}	

		if(temp[temp.length-1] === '&')
			temp += 'demo=' + environment.demo;
		else
			temp += '&demo=' + environment.demo;
				
		//add our API's controller
		if (temp.includes('shop')){
			temp=temp.replace('shop','FindShopProducts');			
		}
		
		if ( this.userInfo.length > 0 ){
			temp += '&cust_excl_vat=' + this.userInfo[0].CUST_Exclude_Vat;
		}
		
		
		temp += this.db_conn_string;	
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/${temp}`,  requestOptions);
	}

	/**
	 * Get Products
	 */
	public fetchBlogData(params: any, initial = 'blogs/classic', perPage: number,): Observable<any> {
		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}

		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		if (!params.page) {
			temp += 'page=1';
		}

		if (!params.perPage) {
			temp += '&perPage=' + perPage;
		}

		return this.http.get(`${environment.SERVER_URL}/${temp}`);
	}

	/**
	 * Get Products
	 */
	public fetchSinglePost(slug: string): Observable<any> {
		return this.http.get(`${environment.SERVER_URL}${'single/' + slug + '?demo=' + environment.demo}`);
	}

	/**
	 * Get Products for home page not used
	 */
	public fetchHomeData(): Observable<any> {
		//return this.http.get(`${environment.SERVER_URL}/${environment.demo}`);
		return this.http.get("http://localhost:1337/demo13");
	}

	/**
	 * Fetch Hot Deals Products (the ones that set featured to true)
	 */
	 public fetchHotDealsProductsPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let temp='FindShopProducts?hot=true';
		temp += this.db_conn_string;	
		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			temp += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/${temp}`,requestOptions);
	}

	
	/**
	 * Fetch NEW Products (the ones that set featured to true)
	 */
	public fetchNewProductsPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let temp='FindShopProducts?new_products=true';
		temp += this.db_conn_string;	
		temp += '&orderby=new_products';
		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			temp += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}	
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/${temp}`, requestOptions);
	}

	/**
	 * Fetch NEW & HOT DEAL Products 
	 */
	public fetchNewHotProductsPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let temp='FindShopProducts?malakies=';
		temp += this.db_conn_string;			
		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			temp += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}	
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/${temp}`, requestOptions);	
	}

	/**
	 * Get products by demo
	 */
	public getSingleProduct(id: string, isQuickView = false): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		
		//all the urls are based on the slug of each product. however we need the prodid 'cause we do not have slug column in our tables and iq works differently
		//let temp = this.products.find(x => x.slug === slug);		
		let params = `demo=${environment.demo}&isQuickView=${isQuickView}&prodID=${id}&${this.db_conn_string}`;
		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			params += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}		
		return this.http.get(`${environment.SERVER_URL_API}api/Retail/FindShopProducts?${params}`,requestOptions);				
	}

	/**
	 * Get single product promise
	 */
	getSingleProductPromise(id: string, suggested:boolean=false){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let params = `demo=${environment.demo}&prodID=${id}${this.db_conn_string}`;
		if ( this.userInfo.length > 0 && this.userInfo[0].CUST_PriceLIst_Header_Id !="-1") {
			params += '&customer_price_list_header=' + this.userInfo[0].CUST_PriceLIst_Header_Id;
		}		
		params+= '&suggested='+suggested;
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/FindShopProducts?${params}`, requestOptions);
	}

	/**
	 * Get Products
	 */
	public fetchHeaderSearchData(searchTerm: string, cat = null): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		
		let temp=`FindShopProducts?perPage=5&searchTerm=${searchTerm}`;
		temp += this.db_conn_string;	
		//return this.http.get(`${environment.SERVER_URL_API}/shop?perPage=5&searchTerm=${searchTerm}&category=${cat}&demo=${environment.demo}`);
		return this.http.get(`${environment.SERVER_URL_API}api/Retail/${temp}`, requestOptions);
	}

	/**
	 * Get Element Products
	 */
	public fetchElementData(): Observable<any> {
		return this.http.get(`${environment.SERVER_URL}/elements/products`);
	}

	/**
	 * Get Element Blog
	 */
	public fetchElementBlog(): Observable<any> {
		return this.http.get(`${environment.SERVER_URL}/elements/blogs`);
	}

	/**
	 * Get all the parameters needed for the website
	 */
	public fetchAllParamsPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:string="";
		sb +=" SELECT * ";
		sb +=" FROM APPLICATION_PROPERTY ";
		sb +=" WHERE APPKEY IN('IQ_WEB_SITE_URL','Telephone','CompanyName','Customer_VAT_On_Mobile','Order_Mobile_Show_On_Pos','IQ_WEB_Shipping_Total_Free_Delivery',";
		sb +=" 'IQ_WEB_SHOW_MASTER_CATEGORIES_HOME_PAGE', 'IQ_WEB_WAREHOUSE_ID', 'IQ_WEB_SHOW_FULL_SCREEN_SLIDER', 'IQ_WEB_SHOW_BRAND_PICTURES','IQ_WEB_SHOW_CAT_PICTURES',";
		sb +=" 'Show_Stock_On_Mobile','IQ_WEB_IMG_Main_Path','Clients_Long','Clients_Lat','IQ_WEB_Show_18_Plus','IQ_WEB_Show_Google_Maps','IQ_WEB_Show_Place_Order',";
		sb +=" 'IQ_WEB_Show_Pay_Now', 'IQ_WEB_SHOW_Bank_Transfer','IQ_WEB_FACEBOOK','IQ_WEB_INSTAGRAM','IQ_WEB_TWITTER','IQ_WEB_YOUTUBE', 'IQ_WEB_SHOW_SIGNIN', 'IQ_WEB_SHOW_SIGNUP', ";
		sb +=" 'IQ_WEB_SHOW_VIVA_WALLET', 'Email', 'IQ_WEB_TIKTOK', 'IQ_WEB_CATS_LINK', 'Point_Percantage','IQ_WEB_SESSION_EXPIRE','IQ_WEB_COOKIE_EXPIRE')";

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	public async storeParams(){
		let result:any = await lastValueFrom(await this.fetchAllParamsPromise());
		this.call_info = result.find(x=>x.APPKEY == "Telephone").APPVALUE;
		this.website_url = result.find(x=>x.APPKEY == "IQ_WEB_SITE_URL").APPVALUE;
		this.Customer_VAT_On_Mobile = result.find(x=>x.APPKEY == "Customer_VAT_On_Mobile").APPVALUE;
		this.Order_Mobile_Show_On_Pos = result.find(x=>x.APPKEY == "Order_Mobile_Show_On_Pos").APPVALUE;
		this.company_name = result.find(x=>x.APPKEY == "CompanyName").APPVALUE;
		this.free_delivery = result.find(x=>x.APPKEY == "IQ_WEB_Shipping_Total_Free_Delivery").APPVALUE;		
		this.show_master_categories_home_page = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_MASTER_CATEGORIES_HOME_PAGE").APPVALUE.toLowerCase());					
		this.warehouseid = result.find(x=>x.APPKEY == "IQ_WEB_WAREHOUSE_ID").APPVALUE;
		this.fullscreenslider = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_FULL_SCREEN_SLIDER").APPVALUE.toLowerCase());
		this.showcatpictures = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_CAT_PICTURES").APPVALUE.toLowerCase());
		this.showbrandpictures = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_BRAND_PICTURES").APPVALUE.toLowerCase());
		this.showStock = JSON.parse(result.find(x=>x.APPKEY == "Show_Stock_On_Mobile").APPVALUE.toLowerCase());
		this.imageURLmainPath = result.find(x=>x.APPKEY == "IQ_WEB_IMG_Main_Path").APPVALUE;
		this.lat= Number(result.find(x=>x.APPKEY == "Clients_Lat").APPVALUE);
		this.long= Number(result.find(x=>x.APPKEY == "Clients_Long").APPVALUE);
		this.plus18 = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_Show_18_Plus").APPVALUE.toLowerCase());
		this.showGoogleMap = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_Show_Google_Maps").APPVALUE.toLowerCase());
		this.showPayCashOnDelivery = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_Show_Place_Order").APPVALUE.toLowerCase());	
		this.showPayWithCreditCard = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_Show_Pay_Now").APPVALUE.toLowerCase());	
		this.showPayBankTransfer = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_Bank_Transfer").APPVALUE.toLowerCase());	
		this.instagram = result.find(x=>x.APPKEY == "IQ_WEB_INSTAGRAM").APPVALUE.toLowerCase();	
		this.facebook = result.find(x=>x.APPKEY == "IQ_WEB_FACEBOOK").APPVALUE.toLowerCase();	
		this.twitter = result.find(x=>x.APPKEY == "IQ_WEB_TWITTER").APPVALUE.toLowerCase();	
		this.youtube = result.find(x=>x.APPKEY == "IQ_WEB_YOUTUBE").APPVALUE.toLowerCase();	
		this.showSignup = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_SIGNUP").APPVALUE.toLowerCase());
		this.showSignin = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_SIGNIN").APPVALUE.toLowerCase());
		this.showVivaWallet = JSON.parse(result.find(x=>x.APPKEY == "IQ_WEB_SHOW_VIVA_WALLET").APPVALUE.toLowerCase());
		this.company_email = result.find(x=>x.APPKEY == "Email").APPVALUE;
		this.tiktok = result.find(x=>x.APPKEY == "IQ_WEB_TIKTOK").APPVALUE.toLowerCase();	
		this.catsLinkFromParams = result.find(x=>x.APPKEY == "IQ_WEB_CATS_LINK").APPVALUE;
		let _points = result.find(x=>x.APPKEY == "Point_Percantage").APPVALUE;			
		if (_points > 0)
			this.showPoints = true;			
		this.sessionExpire = result.find(x=>x.APPKEY == "IQ_WEB_SESSION_EXPIRE").APPVALUE;		
		this.cookieExpire =result.find(x=>x.APPKEY == "IQ_WEB_COOKIE_EXPIRE").APPVALUE;		

	}
	
	/**
	 * Run SQL Queries
	 */
	public execSqlQuery(str: string):Observable<any>{
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };		
		return this.http.get(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${str}${this.db_conn_string}`, requestOptions);
	}

	/**
	 * Run SQL Queries using the toPromise() method
	 */
	public execSqlQueryPromise(str: string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${str}${this.db_conn_string}`, requestOptions);
	}

	public execSqlQueryNew(str: string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${str}${this.db_conn_string}`, requestOptions);
	}

	public execSqlQueryConStrPromise(str: string, db_conn:string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let db_con_str = "&dbConnString="+ db_conn;
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${str}${db_con_str}`,requestOptions);
	}

	/**
	 * Fetch Slides for home page
	 */
	public fetchSlides():Observable<any>{			
		return this.http.get(`${environment.SERVER_URL_API}api/Retail/getSlides?folder=${this.img_folder}${this.db_conn_string}`);
	}

	/**
	 * Fetch Slides for home page Promise however all fetch slides except the contsant ones do not work because of the constructor and how angular works
	 */
	public fetchSlidesPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/getSlides?folder=${this.img_folder}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Update password for password reset page
	 */
	public updatePasswordPromise(id:string, pass:string){	
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/UpdatePassword?custid=${id}&pass=${pass}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Create the menu for categories
	 */
	async setupMenuCategoriesandFetch(){
		let sb:string="";
		sb+=" DECLARE @SUBCATS AS INT";
		sb+=" SET @SUBCATS=0";
		sb+=" SELECT * FROM Product_Categories_Master pcm";
		sb+=" WHERE pcm.DELETEDFLAG=0 ";
		sb+=" AND @SUBCATS <";
		sb+=" (SELECT COUNT(catID) FROM ProductCategories";
		sb+=" INNER JOIN Product_Categories_Master";
		sb+=" ON Cat_Master_Id = PCM_Id ";
		sb+=" INNER JOIN PRODUCTS on PRODCATID = CatID ";
		sb+=" WHERE ProductCategories.DELETEDFLAG=0";
		sb+=" AND pcm.PCM_Id=PCM_Id AND products.PROD_Show_on_mobile=1";
		sb+=" )";
		sb+=" ORDER BY PCM_Seq ASC";
		this.masterCats = await lastValueFrom(await this.execSqlQueryPromise(sb));
		for (let i=0; i<this.masterCats.length;i++){
			await this.fetchSubCats(this.masterCats[i].PCM_Id);		
			this.categoriesWithSubCategories[i] = [];
			this.categoriesWithSubCategories[i] = this.masterCats[i];
			this.categoriesWithSubCategories[i].subcategories = [];
			this.categoriesWithSubCategories[i].subcategories = this.cats;
		}	
		return this.categoriesWithSubCategories;
	}

	/**
	 * Fetch Sub Categories
	 */
	async fetchSubCats(masterCat){
		let sb = "";
		sb += " SELECT * FROM ProductCategories ";
		sb += " INNER JOIN Product_Categories_Master ";
		sb += " ON Cat_Master_Id = PCM_Id ";
		sb += " WHERE ProductCategories.DELETEDFLAG=0 ";
		sb += " AND PCM_Id=";
		sb += masterCat;
		sb += " ORDER BY CatSequence ASC ";
		
		this.cats = await lastValueFrom(await this.execSqlQueryPromise(sb));
		return this.cats;
	}

	/**
	 * Setup Categories 
	 */
	public setupCategories4SideBar(){
		let shop_data = shopData;	
		for(let i=0; i<this.categoriesWithSubCategories.length; i++){		
			shop_data.categories[i] = {
				name: "dummy",
				slug: "dummy",
				count: -1
			};
			shop_data.categories[i].name = this.categoriesWithSubCategories[i].PCM_Description;
			shop_data.categories[i].slug = this.categoriesWithSubCategories[i].PCM_Description.split(' ')[0];		
			shop_data.categories[i].count = this.categoriesWithSubCategories[i].subcategories.length;
		}			
		shopData.categories = shop_data.categories;
	}

	/**
	 * Get Database details and store them here 
	 */
	 async fetchDBconnStringPromise(){
		if(this.db_conn_string == ''){			
			//check if session has connection string stored
			if(typeof window !== 'undefined'){
				if(JSON.parse(sessionStorage.getItem('db_conn_string'))){
					this.db_conn_string = JSON.parse(sessionStorage.getItem('db_conn_string'));
					this.shareProductConnStr = JSON.parse(sessionStorage.getItem('db_conn_string'));
					this.img_folder = JSON.parse(sessionStorage.getItem('img_folder'));
					this.viva_auth = JSON.parse(sessionStorage.getItem('viva_auth'));
					this.viva_source_code = JSON.parse(sessionStorage.getItem('viva_source_code'));
					this.paypal_auth = JSON.parse(sessionStorage.getItem('paypal_auth'));
					this.iqtbl_api_url_based_on_client_domain = JSON.parse(sessionStorage.getItem('iqtbl_api_url_based_on_client_domain'));
					this.working_hours = JSON.parse(sessionStorage.getItem('working_hours'));
					environment.SERVER_URL = JSON.parse(sessionStorage.getItem('SERVER_URL'));
					this.baseURL = JSON.parse(sessionStorage.getItem('SERVER_URL'));
				}
				else{
					let dbstrings:any = await lastValueFrom(await this.fetchDBstrPromise());
				  //get currentt url & prefetch using resolver the db strings
				  const url =  window.location.href;				  
					dbstrings.forEach(element => {
					  if(url.includes(element.identifier_string) && element != undefined){
						if(typeof window !== 'undefined'){
							this.db_conn_string="&dbConnString="+element.db_conn_str.trim();    
							this.shareProductConnStr = element.db_conn_str.trim();
							//console.log(element);
							this.img_folder= element.img_folder.trim();    
							
							this.viva_auth = element.viva_auth.trim();
							
							this.viva_source_code = element.viva_source_code.trim();
							
							
							this.paypal_auth = element.paypal_auth.trim();
							
							this.iqtbl_api_url_based_on_client_domain =  element.api_url;
							
							this.working_hours = element.working_hours;
							
							environment.SERVER_URL = element.url;	
							this.baseURL = element.url;				
						}
						if(typeof window !== 'undefined'){
							sessionStorage.setItem('db_conn_string',JSON.stringify(this.db_conn_string));
							sessionStorage.setItem('img_folder',JSON.stringify(this.img_folder));
							sessionStorage.setItem('viva_auth',JSON.stringify(this.viva_auth));
							sessionStorage.setItem('viva_source_code',JSON.stringify(this.viva_source_code));							
							sessionStorage.setItem('paypal_auth',JSON.stringify(this.paypal_auth));
							sessionStorage.setItem('paypal_auth',JSON.stringify(this.paypal_auth));
							sessionStorage.setItem('iqtbl_api_url_based_on_client_domain',JSON.stringify(this.iqtbl_api_url_based_on_client_domain));
							sessionStorage.setItem('working_hours',JSON.stringify(this.working_hours));
							sessionStorage.setItem('SERVER_URL',JSON.stringify(element.url));	
						}				
						if (this.working_hours){
						  this.working_hours_monday = element.working_hours_monday.trim();
						  this.working_hours_tuesday = element.working_hours_tuesday.trim();
						  this.working_hours_wednesday = element.working_hours_wednesday.trim();
						  this.working_hours_thursday = element.working_hours_thursday.trim();
						  this.working_hours_friday = element.working_hours_friday.trim();
						  this.working_hours_saturday = element.working_hours_saturday.trim();
						  this.working_hours_sunday = element.working_hours_sunday.trim();					  
						}					
					  }
					});	
				}
			}			
		}
		await this.updateUserPricelist();
		this.updateVATtext();					 				
	}

	/**
	 * Fetch the Database Strings from IQ_WEB database so that we can have many clients on the same website/angular app
	*/	  
	public fetchDBstrPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		
		return this.http.get<any>("https://backend-api.iqbookingcy.com/api/Retail/getDBstrings?dbConnString=IQ_WEB_DB",requestOptions);
	}

	/**
	 * Fetch the Database Strings from IQ_WEB database so that we can have many clients on the same website/angular app
	*/	  
	public fetchDBstr():Observable<any>{
		return this.http.get(`${environment.SERVER_URL_API}api/Retail/getDBstrings?dbConnString=IQ_WEB_DB`);
	}		
	
	/**
	 * Get the slides that will be shown on the first page. only this one works for slide because of the constructor
	 */
	public fetchSlidesConst(){
		let slide = [];
		slide[0] = { 
			intro_subtitle: "slide title 1",
			intro_title1: "intro title 1",
			intro_title2: "title 2",
			intro_title3: "title 3",
			price: 59,
			price_decimal: 99,
			link_title: "Shop Now",
			link: "",
			image_src: "assets/images/home/sliders/vapes/slide-1.png",
			display: false
		};
		slide[1] = { 
			intro_subtitle: "slide title",
			intro_title1: "intro title",
			intro_title2: "text",
			intro_title3: "text",
			price: 49,
			price_decimal: 99,
			link_title: "Shop Now",
			link: "",
			image_src: "assets/images/home/sliders/vapes/slide-2.png",
			display: false
		};
		slide[2] = { 
			intro_subtitle: "slide title 3",
			intro_title1: "intro title 3",
			intro_title2: "text 3",
			intro_title3: "text 3",
			price: 59,
			price_decimal: 99,
			link_title: "Shop Now",
			link: "",
			image_src: "assets/images/home/sliders/vapes/slide-3.png",
			display: true
		}

		return slide;
	}

	/**
	 * Fetch popular categories both from master and products for the home page
	 */
	public async fetchPopularCategories(){	
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:string="";		
		sb+=(" SELECT CATID, CatCode as 'code', CatDescription as 'Description',CAT_IQWEB_POPULAR_ORDER AS 'ORDER' ");
		sb+=("  FROM ProductCategories AS C ");
		sb+=(" WHERE C.CAT_IQWEB_POPULAR=1 AND C.DELETEDFLAG=0 AND CAT_IQWEB_POPULAR_ORDER != -1");
		sb+=(" ORDER BY 'ORDER' ASC");
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Fetch wharehouses for each product 
	 */
	public async fetchProductWharehouses(prodid:string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:string = "SELECT WH_Web_Description";
		sb += " FROM WAREHOUSE_STOCK";
		sb += " INNER JOIN WAREHOUSES";
		sb += " ON WS_WH_Id = WH_Id";
		sb += " WHERE WS_Prod_Id=" + prodid;	
		sb += "  AND WS_Stock >0";
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Fetch all master categories 
	 */
	 public async fetchMasterCategories(){	
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:string="";	
		sb+=(" SELECT PCM_Id as 'CATID', PCM_Code as 'code', PCM_Description as 'Description', PCM_Seq as 'ORDER' ");
		sb+=(" FROM PRODUCT_CATEGORIES_MASTER ");
		sb+=(" WHERE DELETEDFLAG=0 ");
		sb+=(" ORDER BY PCM_Seq ASC ");
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Fetch brands for home page
	 */
	public fetchBrandsPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:String="";
		sb+=(" SELECT * ");
		sb+=(" FROM Brands ");
		sb+=(" WHERE DELETEDFLAG=0 ");
		sb+=(" AND Brand_show_on_home_page=1 ");
		sb+=(" ORDER BY Brand_Sequences, Brand_name ASC ");
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Fetch brands for sidebar for a specific category
	 */
	 public fetchProductsBrandsPromise(catid:string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:String="";
		sb+=" SELECT DISTINCT Brand_name, Brand_Id, Brand_Sequences, Brand_code ";
		sb+="FROM PRODUCTS";
		sb+=" INNER JOIN BRANDS  ";
		sb+=" ON BRAND_ID = PROD_BRAND_ID ";
		sb+=" WHERE PRODCATID="+catid;	
		sb+=" AND PROD_Show_on_mobile=1";
		sb+="ORDER BY Brand_Sequences, Brand_name ASC";			
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Fetch all the data neede for the about us page
	 */
	async getAboutPageElements(){
		let sb:string ="";	
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = 2 ";
		sb += "AND IQWEB_PAGES.DELETEDFLAG=0 ";
		let res = await lastValueFrom(this.execSqlQueryPromise(sb));
		this.title1 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 1").IQWPP_TEXT;				
		this.vision = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "our vision").IQWPP_TEXT;		
		this.title2 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 2").IQWPP_TEXT;		
		this.mission = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "our mission").IQWPP_TEXT;		
		this.title3 = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "about title 3").IQWPP_TEXT;		
		this.who_we_are = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "who we are").IQWPP_TEXT;		
		this.imageName = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "top image about").IQWPP_IMG;													
	}
	
	/**
	 * Fetch all the data neede for the about us page
	 */
	async getPageContactElements(){
		let sb:string ="";
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = 1 ";
		sb += "AND IQWEB_PAGES.DELETEDFLAG=0 ";

		let res = await lastValueFrom(this.execSqlQueryPromise(sb));
		this.phone = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "office phone").IQWPP_TEXT;
		this.address = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "office address").IQWPP_TEXT;
		this.email = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "office email").IQWPP_TEXT;
		this.working_hoursContact = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "working hours").IQWPP_TEXT;
		this.working_daysContact = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "working days").IQWPP_TEXT;
		this.weekendContact = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "weekend").IQWPP_TEXT;
		this.contact_info = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "contact info").IQWPP_TEXT;
		this.imageNameContact = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "top image contact").IQWPP_IMG;					
		//this.imageURLContact = environment.Images_Path+"/" + this.img_folder + "/contact/"+this.imageName;

	}

	/**
	 * Fetch all the information for Delivery
	 */
	async getProductDeliveryInformation(){
		let sb:string ="";
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = (SELECT IQWP_ID FROM IQWEB_PAGES_PAGE WHERE IQWP_PAGE = 'product' AND DELETEDFLAG=0) ";
		sb += "AND IQWEB_PAGES.DELETEDFLAG=0 ";

		let res = await lastValueFrom(this.execSqlQueryPromise(sb));
		this.productDeliveryInfo = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "delivery information").IQWPP_TEXT;							
	}

	/**
	 * Fetch all the information for Returns & Cancellation Policy
	 */
	async getReturnCancellationInformation(){
		let sb:string ="";
		sb += "SELECT ";
		sb += "*  ";
		sb += "FROM  ";
		sb += "IQWEB_PAGES  ";
		sb += "INNER JOIN IQWEB_PAGES_PAGE ON IQW_pAGE_ID = IQWP_ID  ";
		sb += "INNER JOIN IQWEB_PAGES_POSITION ON IQW_POSITION_ID = IQWPP_ID ";
		sb += "WHERE IQW_PAGE_ID = (SELECT IQWP_ID FROM IQWEB_PAGES_PAGE WHERE IQWP_PAGE = 'product' AND DELETEDFLAG=0) ";
		sb += "AND IQWEB_PAGES.DELETEDFLAG=0 ";

		let res = await lastValueFrom(this.execSqlQueryPromise(sb));
		this.productCancellationReturnInfo = res.find(x=>x.IQWPP_POSITION_DESC.trim() == "returns cancellation policy").IQWPP_TEXT;							
	}

	/**
	 *  Pop up message	 		 
	 */
	popupMsg(_title, _msg, _icon){
		Swal.fire({
		  title: _title,
		  html: '<pre>'+_msg+'</pre>',
		  icon: _icon,
		})
	  }

	/**
	 * Save Data through API	   
	 */
	getSavePromise(Table_Name :string,Update_Table :boolean, Col_Value:Array<{Column_Name: string, Column_Value: string}>,id_Column :string)
	{
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		
		interface Col_Value {
			column: string;
			value: string;
		}
	var Save_data={'Table_Name':Table_Name,'Update_Table':Update_Table,'Col_Value' :Col_Value,'id':id_Column}
	
	return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/`+'Save?dummy=""'+this.db_conn_string,JSON.stringify(Save_data) ,config);
	}

	/**
	 * Get Login Info
	 */
	getLoginPromise(user,pass){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };
		
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/`+'login?username='+user+'&password='+pass+this.db_conn_string, requestOptions);
	}

	/**
	 * Get Users Orders Stored in db
	 */
	getUsersOrdersPromise(){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

	let sb:string="";		   
	sb+=" SELECT *  ";
	sb+=" FROM orders ";
	sb+=" WHERE ORDER_IS_WEB=1 AND DELETEDFLAG=0 ";
	sb+=" AND ORDERCUSTID =" + this.userInfo[0].CUSTID;
	sb+=" ORDER BY ORDERID DESC "
	
	return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`, requestOptions);
	}

	/**
	 * Get Users Order Details for each order Stored in db order details
	 */
	getUsersOrderDetailsPromise(orderid:string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let sb:string="";		   
		sb+=" SELECT ORDERID,ORDERCODE,ORDERCUSTID,ORDERTOTAL,ORDERDT,PRODDESC,ORDERDQTTY  ";
		sb+=" FROM orders ";
		sb+=" INNER JOIN ORDERDETAILS ";
		sb+=" ON ORDERDORDERID = ORDERID ";
		sb+=" INNER JOIN PRODUCTS ";
		sb+=" ON PRODID = ORDERDPORDID ";
		sb+=" WHERE ORDER_IS_WEB=1 AND ORDERS.DELETEDFLAG=0 ";			
		sb+=" AND ORDERID =" + orderid;
		sb+=" AND ORDERCUSTID ="+this.userInfo[0].CUSTID;
		
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/select_query?qry=${sb}${this.db_conn_string}`,requestOptions);
	}

	/**
	 * Save Order (order header, detail, comments, etc...)
	 */
	saveOrderPromise(prods,total,notes,payment=""){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		let params = '?total='+total;
		params += this.db_conn_string;
		params += '&custid=' + this.userInfo[0].CUSTID;
		params += '&orderMobShowPos=' + this.Order_Mobile_Show_On_Pos;
		params += '&notes=' + notes;
		params += '&custname=' + this.userInfo[0].CUSTNAME;
		params += '&shipCost=' + this.shippingCost;
		params += '&shipDesc=' + this.shippingDesc;
		params += '&shipId=' + this.shippingId;
		params += '&payment=' + payment;
		params += '&cust_exlude_vat=' + this.userInfo[0].CUST_Exclude_Vat;
		if(this.redeemPoints > 0)
			params += '&redeemPoints=' + this.redeemPoints;


		//params += '&custDiscount=' + this.userInfo[0].custDiscount;
		//let Save_data = prods;//{mal:'malakies', mal1:'malakies stin devtera'};

		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/SaveOrder${params}`, JSON.stringify(prods), config);
	}

	/**
	 * Add customer detail as well as product details to the notify list when product is out of stock
	 */
	addCustomerToNotifyListPromise(email:any){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		let params = '?custemail=' + email;	
		params += this.db_conn_string;
		if(this.userInfo.length>0){
			params += '&custid=' + this.userInfo[0].CUSTID;		
			params += '&custname=' + this.userInfo[0].CUSTNAME;	
			params += '&custusername=' + this.userInfo[0].CUST_USERNAME;
			params += '&custmobile=' + this.userInfo[0].CUSTMOBILEPHONE;	
		}					
		params += '&prodid=' + this.notify_arr[0].id;
		params += '&prodcode=' + this.notify_arr[0].code;
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/SaveNotifyOutOfStock${params}`, JSON.stringify(this.notify_arr[0]), config);
	}

	/**
	 * Show 18 PLUS WARNING
	 */
	//dipslay 18+ message
	confirmPlus18(){		
		if((this.plus18) && !JSON.parse(sessionStorage.getItem('verify18'))){
		 Swal.fire({//the new version is called sweet alert 2 use that on instead from now on
		   title: 'Age Verification',
		   text: 'You MUST be at least 18 years of age to use this site. Please verify your age, are you at least 18 years old?',
		   //icon: 'info',
		   allowOutsideClick: false,
		   allowEscapeKey: false,
		   allowEnterKey: false,
		    imageUrl: 'https://static.vecteezy.com/system/resources/previews/005/476/277/original/under-18-forbidden-round-icon-sign-illustration-eighteen-or-older-persons-adult-content-18-plus-only-rating-isolated-on-white-background-free-vector.jpg',
			imageWidth: 200,
  			imageHeight: 200,
  			imageAlt: 'Custom image',
		   showCancelButton: true,
		   confirmButtonText: 'Yes, I am!',
		   cancelButtonText: 'No, I am not'
		 }).then((result) => {
		   if (result.value) {
			 Swal.fire(
			   'Welcome to '+ this.company_name + ' !',
			   'You can now view our website.',
			   'success'
			 )
			 //write info to cookies
			 sessionStorage.setItem('verify18', 'true');
		   } else if (result.dismiss === Swal.DismissReason.cancel) {				
			Swal.fire({			
				title: 'Under Age',
				text: 'Sorry, your age does not permit you to enter at this time.',
				allowEnterKey: false,
				allowEscapeKey: false,
				allowOutsideClick: false,
				showCloseButton: false,
				showConfirmButton: false
			})		
			 //window.location.href = "#";			 
		   }
		 })
		}    
	}

	/**
	 * Get Location in order to distinguish country for shipping
	 * not needed anymore since we get the country from the address details of the loged in user
	 */
	
	getIPLocation(){
		return this.http.get<any>('https://ipapi.co/json/');
	}
	

	forgotPassword(email){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}

		let params = '?email='+email; 
		params += this.db_conn_string;
		params += '&domainName='+ this.website_url;
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/ForgotPassword`+params,"",config);
	}

	async getStoredUserInfo(){
		let _tmp:any
		if (typeof window !== 'undefined') {
			_tmp = JSON.parse(localStorage.getItem('loginData'));			
		}
			
		if(_tmp){
			this.userInfo = _tmp;	
			this.updateVATtext();		
		}
	}

	/**
	 * Get the location from the adress details of the loged in user	
	 * if the loged in users location is empty then use ipapi
	 * Get location based on ip address first 30000 free
	 */
	async getLocation(){
		if(this.userInfo.length>0){
			if(this.userInfo[0].CUSTCOUNTRY === "Cyprus")
				this.ipBasedCountry = "CY";
			else if((this.userInfo[0].CUSTCOUNTRY === "")){
				let location:any = await lastValueFrom(this.getIPLocation());
				this.ipBasedCountry = location.country;
			}
			else{				
				this.ipBasedCountry = "International";				
			}			
		}
		else{
			let location:any = await lastValueFrom(this.getIPLocation());
			this.ipBasedCountry = location.country;
		}
		//console.log("Country: ", this.ipBasedCountry );
		
	}

	sendContactEmail(name,clients_email,phone,subject,message){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		let rec_email= this.email;				
		let params = "?name="+name;
		params += "&clients_email=" + clients_email;
		params += "&phone=" + phone;
		params += "&subject=" + subject;
		message += ".     User Info: Name: " + name + " Phone: " + phone + " Email: " + clients_email;
		//params += "&msg=" + message;
		message = JSON.stringify(message);
		params += "&receipients_email=" + rec_email;
		params +=  this.db_conn_string;
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/SendContactEmail`+params,message,config);
	}

	/**
	 * fetch image name for background image that will be displayed on categories
	 */
	async fetchCatsImg(){
		let res =  await lastValueFrom(this.execSqlQueryPromise("SELECT IQWPP_IMG FROM IQWEB_PAGES_POSITION WHERE IQWPP_POSITION_DESC ='home page categories image' AND DELETEDFLAG=0"));
		if(res.length > 0)
			this.home_cats_background_img = res[0].IQWPP_IMG;		
	}

	/**
	 * Get Viva Wallet duplicates when storing vivasuccess order
	 */
	getVivaCodeDuplicated(paymentid:string){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${this.api_key}`
		});		
		const requestOptions = { headers: headers };

		let params = "?vivacode=" + paymentid;
		params += this.db_conn_string;

		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/GetVivaForDuplicates${params}`,requestOptions);
	}

	/**
	 * Viva Wallet Payment
	 */	
	fetchOrderCode(malakies:string, urladdress:string,authentication:string){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}		
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/get_payment_ordercode?cust_det=`+malakies+'&urladdress='+urladdress+'&auth='+authentication+'&source_code='+this.viva_source_code, config).pipe
		(		  
			catchError(this.handleError)		
		);	
	}

	private handleError(error: HttpErrorResponse) {
	if (error.error instanceof ErrorEvent) {
		// A client-side or network error occurred. Handle it accordingly.
		console.error('An error occurred:', error.error.message);	
		this.popupMsg(this.company_name,'An error occurred: ' + error.error.message,error);
	} else {
		// The backend returned an unsuccessful response code.
		// The response body may contain clues as to what went wrong.
		console.error(
		`Backend returned code ${error.status}, ` +
		`body was: ${error.error}`);  
		/*  this.cartService.dialogMsg=`Backend returned code ${error.status}, ` + `body was: ${error.error}`;
		this.dialog.open(ShowDialogService);  */     
	}
	// Return an observable with a user-facing error message.
	return throwError(
		'Something bad happened; please try again later.');
	}

	/**	 
	 * send email to the company that there is a new order
	 */
	sendEmailCompany(items:any){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		let rec_email= this.email;				
		let params = "?name="+this.company_name;
		params += "&clients_email=" + this.userInfo[0].CUSTEMAIL;
		params += "&phone=";
		params += "&subject=New web order";
		let message = "User Info<br>Name: " + this.userInfo[0].CUSTNAME + "<br>Phone: " + this.userInfo[0].CUSTMOBILEPHONE + "<br>Email: "+this.userInfo[0].CUSTEMAIL + "<br>Item(s)<br>";
		items.forEach(element => {
			message+= element.name + "x"+ element.qty +"<br>";
		});
		//params += "&msg=" + message;
		message = JSON.stringify(message);
		params += "&receipients_email=" + this.company_email;
		params +=  this.db_conn_string;		
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/SendContactEmail${params}`,message,config);
	}

	/**
	 *Send emai to the customer that his order has been completed successfully 
	*/
	sendCustomerConfirmationEmail(items:any){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		let rec_email= this.email;				
		let params = "?name="+this.company_name || "";
		params += "&clients_email=" + this.userInfo[0].CUSTEMAIL || "";
		params += "&phone= ";
		params += "&subject=New web order";
		let message = "Thank you for your order." +"<br>Item(s)<br>";
		items.forEach(element => {
			message+= element.name + " x "+ element.qty +" <br> ";
		});
		//params += "&msg=" + message;
		message = JSON.stringify(message);
		params += "&receipients_email=" + this.userInfo[0].CUSTEMAIL || "";
		params +=  this.db_conn_string;
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/SendContactEmail${params}`,message,config);
	}

	/**
	* when the pricelist changes users have to log out and login again to get the changes
	* check if pricelist has changes and get the user info
	*/
	async updateUserPricelist(){
		if(this.db_conn_string && this.userInfo.length > 0  && window != undefined){
			let sb:string = "SELECT * FROM customers WHERE CUSTID=" + this.userInfo[0].CUSTID ;
			let _userinfo = await lastValueFrom(this.execSqlQueryPromise(sb));
			if(this.userInfo[0].CUST_PriceLIst_Header_Id != _userinfo[0].CUST_PriceLIst_Header_Id){
				this.userInfo[0].CUST_PriceLIst_Header_Id = _userinfo[0].CUST_PriceLIst_Header_Id;
				//localStorage.setItem('loginData',JSON.stringify(this.userInfo));
			}
			this.userInfo[0].CUST_POINT_Balance = _userinfo[0].CUST_POINT_Balance;
			this.userInfo[0].CUST_POINTS_Onhold = _userinfo[0].CUST_POINTS_Onhold;
			//also check if the country changed and save it here
			if(this.userInfo[0].CUSTCOUNTRY != _userinfo[0].CUSTCOUNTRY){
				this.userInfo[0].CUSTCOUNTRY = _userinfo[0].CUSTCOUNTRY;
			}
			localStorage.setItem('loginData',JSON.stringify(this.userInfo));
		}
	}
	
	logError(message:string, url:string){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}
		
		message += " ***url***: " + url;
		message += " ***userInfor***: " + JSON.stringify(this.userInfo[0] || '');	
		message += " ***cart***: " + JSON.stringify(this.cartService.cartItems || '');	
		let sStorage=this.getAllSessionData();
		let lStorage =this.getAllLocalData();
		let params = "?msg=ANGULAR - "+this.db_conn_string;
		let data = message + " Local Session Data: "+ JSON.stringify(lStorage) + "Session Data: "+JSON.stringify(sStorage);
		
		return this.http.post<any>(`${environment.SERVER_URL_API}api/Retail/ErrorLog`+params,JSON.stringify(data),config);
	}

	getAllSessionData(): { [key: string]: any } {
		const sessionData: { [key: string]: any } = {};
		for (let i = 0; i < sessionStorage.length; i++) {
		  const key = sessionStorage.key(i);
		  if (key) {
			const value = sessionStorage.getItem(key);
			if (value) {
			  sessionData[key] = JSON.parse(value);
			}
		  }
		}
		return sessionData;
	}
	
	getAllLocalData(): { [key: string]: any } {
		const sessionData: { [key: string]: any } = {};
		for (let i = 0; i < localStorage.length; i++) {
		  const key = localStorage.key(i);
		  if (key && key!="cookiesExpirationDate") {
			const value:any = localStorage.getItem(key);
			if (value && value != "demo13") {
			  sessionData[key] = JSON.parse(value);
			}
		  }
		}
		return sessionData;
	}

	updateVATtext(){		
		if(this.userInfo.length>0 && this.userInfo[0].CUST_Exclude_Vat)
			this.vattext = '*VAT NOT Included';	
		else 
			this.vattext = '*VAT Included';	
		
	}

	getGoogleReviews(placeid:string){
		var config = {
			headers : {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.api_key}`
			}
		}		
		//const meta = domainMetaMap[this.website_url];
		//let placeid = meta.placeid;
		let params="?placeid=" + placeid;
		params += this.db_conn_string;
		return this.http.get<any>(`${environment.SERVER_URL_API}api/Retail/GetGoogleReviews`+params, config);		
	}
}