export const environment = {
  production: true,
  demo: 'demo13',
  //SERVER_URL: 'https://d-themes.com/angular/molla/server',
  //SERVER_URL: 'http://localhost/',
  SERVER_URL: 'http://176.111.175.44/',//3000 --when exporting for the server
  //SERVER_URL: 'http://93.109.209.225/', //cyta server
  //SERVER_URL_API: 'http://localhost:8081/',//8080 for export else 65053
  //SERVER_URL_API: 'http://93.109.209.225:8080/', //cyta server
  SERVER_URL_API: 'https://backend-api.iqbookingcy.com/', //for export
  Images_Path: 'assets/images/',
  version: '2.1.3'
};

//when exporting a new version 1. change the server.ts to  const distFolder = join(process.cwd(), 'browser'  2. environment.prod show be  SERVER_URL: 'http://176.111.175.44/
//coommand to build=  npm run build:ssr
/**
 * 2.1.3 -- added greece for titos and shipping 15 euro
 * 2.1.2 -- email on large orders was'nt send. too long url
 * 2.1.1 -- corrected email from paypal and vivapayment
 * 2.1.0 -- royaltrading corrected reedem point they dont have.
 * 2.0.9 -- added royaltrading and corrected registration button on error (it was getting disabled)
 * 2.0.8 -- spinning
 * 2.0.7 -- removed master cats that do not have product and should not be displayd on web.
 * 2.0.6 -- email on checkout added await
 * 2.0.5 -- corrected path for server.ts
 * 2.0.4 -- corrected executesql query with promise on all calls.
 * 2.0.3 -- email sending on payment fixed
 * 2.0.2 -- quick view fixed
 * 2.0.1 -- removed all toPromise() and added lastvaluefrom
 * 2.0.0 -- major update to bearer authentication api deliver retail controller
 * 1.4.1 -- remove breadcrumbs not working and the category link from products
 * 1.3.9 -- added cube for advertising (it will go live when Lazos is ready with iq)
 * 1.3.8 -- added await for checkout when viva is pressed in order to wait to check for stock first
 * 1.3.7 -- updated keywords for antonis
 * 1.3.6 -- excluded vat and all clients must have country for shipping
 * 1.3.5 -- youtube videos, stock to be checked from cart as well, few more changes/improvements
 * 1.3.4 -- solve the problem of duplicated entries in viva wallet payments
 * 1.3.3 -- try catch on save vivawallet and paypal with new api calls to save errors on event viewer on server
 * 1.3.2 -- seccion expirty and cookies expiry
 * 1.3.1 -- update for Antoni iphone 17.1. menu on mobile corrected
 * 1.3.0 -- update title for home page as antonis requested it
 * 1.2.9 -- iphone ui corrected, search, removed iphone loop, ipapi only for no country and not loged int, and fixed elenis save removed description
 * 1.2.7 -- change the way international shipping is working removing ipapi website 
 * 1.2.6 -- VAT INCLUDED
 * 1.2.5 -- change dots on slider for lakis.
 * 1.2.4 -- mobile version for antonis, and keywords, vivawallet prevent double entries on refresh
 * 1.2.3 -- corrected redeem point to be stored on local storage on vivawallet and points cast to number add notes not to be stored zero
 * 1.2.2 -- update userpricelist whithout the need to log out and login again, set variants order, redeem points
 * 1.2.1 -- 7/8/23 new parameters on how to display cagegories on home page e.g. list 3cols, register and check out by country for vivawallet drop down box, viva wallet first and new viva wallet image
 * 1.2.0 -- major version release with angular ssr and message if email does not exist on forgot password
 * 1.1.3 -- corrected forgot password email reset and titos keywords and description
 * 1.1.2 -- updated keywords for royal
 * 1.1.1 -- added www for analytics to both sides
 * 1.1.0 -- deleted all the unneccessary components and pages in order to improve google indexing. added titles to pages
 * 1.0.30 -- ispos user set to 1 for all registered user from web
 * 1.0.29 -- when loading single product and loading popular category and on all products changing price and items per page
 * 1.0.28 -- ena shisto pramata payapal fix, carousel. kai allo ena shisto pramata me queries etc...
 * 10.027 -- fixed add to wishlist for related and telephone not to be 8 digits only
 * 1.0.26 -- fixed quickview on home page and send email confirmation to client and to company email
 * 1.0.25 -- viva shipping was lost when hitting back on chrome
 * 1.0.24 -- viva wallet is now working
 * 1.0.23 -- viva wallet payment as an option
 * 1.0.22 -- background image for popular cats & changed image dimensions when on mobile
 * 1.0.21 -- added paramters to show signin and sing up tabs on menu
 * 1.0.20 -- added the terms, policy and returns pages into the three tables pages,position and pages_page 
 */
