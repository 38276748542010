import { HttpClient } from '@angular/common/http';
import { ParseError } from '@angular/compiler';
import { Component, Inject, booleanAttribute } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'molla-vivasuccess',
  templateUrl: './vivasuccess.component.html',
  styleUrls: ['./vivasuccess.component.scss']
})


export class VivasuccessComponent {
  constructor(public  apiService:ApiService, public cartService:CartService, @Inject(Router) public router:Router,@Inject(ActivatedRoute) 
  private activatedRoute: ActivatedRoute,  private httpClient: HttpClient){
  }

  public cartItems:any;
  public priceTotal:any;
  public notes:string;
  public paymentid:string;
  public dbResolver:any;
  public pageLoadedOnce:boolean;

  async ngOnInit(): Promise<void> {
    try {                    
        //store page loaded so that if the user refreshes it wont store the same order again
      let res = sessionStorage.getItem('pageLoadedOnce');
      if(res == null){
        this.pageLoadedOnce = false;
      }
      else{
        this.pageLoadedOnce = JSON.parse(res);
      }
      //console.log(res, " -- ", this.pageLoadedOnce);
      if(typeof window !== 'undefined'){
        await this.apiService.fetchDBconnStringPromise();
        await this.apiService.storeParams();
      }

      if (typeof window !== 'undefined') 
        if(!this.apiService.shippingId){
          this.apiService.shippingId = JSON.parse(localStorage.getItem('shipping_id'));
          this.apiService.shippingDesc = JSON.parse(localStorage.getItem('shipping_description'));
          this.apiService.shippingCost = JSON.parse(localStorage.getItem('shipping_cost'));
          this.apiService.redeemPoints = JSON.parse(localStorage.getItem('redeemPoints'));
        }

      
      // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
      this.activatedRoute.queryParams.subscribe(params => {
        this.paymentid = params['s'];      
      });
      
      this.readPaymentDetails();
      let vivaCodeisDuplicated = await lastValueFrom(this.apiService.getVivaCodeDuplicated(this.paymentid));
      vivaCodeisDuplicated = JSON.parse(vivaCodeisDuplicated);
      if(!this.pageLoadedOnce &&  !vivaCodeisDuplicated){      
        if(this.paymentid.length>1){
          this.placeOrderClicked(event);
          sessionStorage.setItem('pageLoadedOnce', 'true');
        }    
        else{
          this.apiService.popupMsg("Something went wrong!","No payment id","error");         
        }  
        
        
      }
      else{
        this.apiService.popupMsg("Order already send successfully!","You can now navigate to home page. ","info");
      }
        
    } catch (error) {              
       await this.apiService.logError(error, this.router.url);
    }
        
  }

  async placeOrderClicked(event:any){
    try {
      let res = await lastValueFrom(this.apiService.saveOrderPromise(this.cartItems, this.priceTotal, this.notes, "VivaWallet Id: "+this.paymentid));
    if(res != "success")
    {
      this.apiService.popupMsg("Something went wrong!","Order not saved.Contact the shop","error");
    }
    else{      
      //clear cart
      await lastValueFrom(this.apiService.sendEmailCompany(this.cartItems));
      await lastValueFrom(this.apiService.sendCustomerConfirmationEmail(this.cartItems));
      this.cartItems.forEach(item =>{
        this.cartService.removeFromCart(item);
      })				
    }
      
    } catch (error) {
      await this.apiService.logError(error, this.router.url);
    }
  }	

  async readPaymentDetails(){
    try {
      if (typeof window !== 'undefined') {
        this.cartItems = JSON.parse(localStorage.getItem('cartItems'));
        this.priceTotal = JSON.parse(localStorage.getItem('totalPrice'));
        this.notes = JSON.parse(localStorage.getItem('orderNotes'));
      }
      //this.paymentid = localStorage.getItem(JSON.parse('onlinepaymentcode'));    
      //console.log(JSON.stringify(this.cartItems) + " " +this.priceTotal+ " " +this.notes+ " " +this.paymentid + " redeemPoints: " + this.apiService.redeemPoints);      
    } catch (error) {
      await this.apiService.logError(error, this.router.url);
    }    
  } 

}
