export class AddressComponent {
    long_name?: string;
    short_name?: string;
    types?: string[];
}

export class TimePeriod {
    date?: string;
    day?: number;
    time?: string;
}

export class OpeningHours {
    open_now?: boolean;
    periods?: { open?: TimePeriod, close?: TimePeriod }[];
    weekday_text?: string[];
}

export class Location {
    lat?: number;
    lng?: number;
}

export class Viewport {
    northeast?: Location;
    southwest?: Location;
}

export class Geometry {
    location?: Location;
    viewport?: Viewport;
}

export class Photo {
    height?: number;
    html_attributions?: string[];
    photo_reference?: string;
    width?: number;
}

export class PlusCode {
    compound_code?: string;
    global_code?: string;
}

export class Review {
    author_name?: string;
    author_url?: string;
    language?: string;
    original_language?: string;
    profile_photo_url?: string;
    rating?: number;
    relative_time_description?: string;
    text?: string;
    time?: number;
    translated?: boolean;
}

export class Result {
    address_components?: AddressComponent[];
    adr_address?: string;
    business_status?: string;
    current_opening_hours?: OpeningHours;
    delivery?: boolean;
    formatted_address?: string;
    formatted_phone_number?: string;
    geometry?: Geometry;
    icon?: string;
    icon_background_color?: string;
    icon_mask_base_uri?: string;
    international_phone_number?: string;
    name?: string;
    opening_hours?: OpeningHours;
    photos?: Photo[];
    place_id?: string;
    plus_code?: PlusCode;
    rating?: number;
    reference?: string;
    reviews?: Review[];
    types?: string[];
    url?: string;
    user_ratings_total?: number;
    utc_offset?: number;
    vicinity?: string;
    website?: string;
}

export class Place {
    html_attributions?: string[];
    result?: Result;
    status?: string;
}
